import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'CustomIcon4',
  viewBox: '0 0 24 24',
  defaultProps: {
    color: 'black',  // Default color is set to black
  },
  path: (
    <>
      <path
        d="M7.75 9C7.75 8.80109 7.67098 8.61032 7.53033 8.46967C7.38968 8.32902 7.19891 8.25 7 8.25C6.80109 8.25 6.61032 8.32902 6.46967 8.46967C6.32902 8.61032 6.25 8.80109 6.25 9V18C6.25 18.1989 6.32902 18.3897 6.46967 18.5303C6.61032 18.671 6.80109 18.75 7 18.75C7.19891 18.75 7.38968 18.671 7.53033 18.5303C7.67098 18.3897 7.75 18.1989 7.75 18V9ZM12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25ZM17.75 13C17.75 12.8011 17.671 12.6103 17.5303 12.4697C17.3897 12.329 17.1989 12.25 17 12.25C16.8011 12.25 16.6103 12.329 16.4697 12.4697C16.329 12.6103 16.25 12.8011 16.25 13V18C16.25 18.1989 16.329 18.3897 16.4697 18.5303C16.6103 18.671 16.8011 18.75 17 18.75C17.1989 18.75 17.3897 18.671 17.5303 18.5303C17.671 18.3897 17.75 18.1989 17.75 18V13Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.943 1.25C9.634 1.25 7.825 1.25 6.413 1.44C4.969 1.634 3.829 2.04 2.934 2.934C2.039 3.829 1.634 4.969 1.44 6.414C1.25 7.825 1.25 9.634 1.25 11.943V12.057C1.25 14.366 1.25 16.175 1.44 17.587C1.634 19.031 2.04 20.171 2.934 21.066C3.829 21.961 4.969 22.366 6.414 22.56C7.825 22.75 9.634 22.75 11.943 22.75H12.057C14.366 22.75 16.175 22.75 17.587 22.56C19.031 22.366 20.171 21.96 21.066 21.066C21.961 20.171 22.366 19.031 22.56 17.586C22.75 16.175 22.75 14.366 22.75 12.057V11.943C22.75 9.634 22.75 7.825 22.56 6.413C22.366 4.969 21.96 3.829 21.066 2.934C20.171 2.039 19.031 1.634 17.586 1.44C16.175 1.25 14.366 1.25 12.057 1.25H11.943ZM3.995 3.995C4.565 3.425 5.335 3.098 6.614 2.926C7.914 2.752 9.622 2.75 12 2.75C14.378 2.75 16.086 2.752 17.386 2.926C18.665 3.098 19.436 3.426 20.006 3.995C20.575 4.565 20.902 5.335 21.074 6.614C21.248 7.914 21.25 9.622 21.25 12C21.25 14.378 21.248 16.086 21.074 17.386C20.902 18.665 20.574 19.436 20.005 20.006C19.435 20.575 18.665 20.902 17.386 21.074C16.086 21.248 14.378 21.25 12 21.25C9.622 21.25 7.914 21.248 6.614 21.074C5.335 20.902 4.564 20.574 3.994 20.005C3.425 19.435 3.098 18.665 2.926 17.386C2.752 16.086 2.75 14.378 2.75 12C2.75 9.622 2.752 7.914 2.926 6.614C3.098 5.335 3.426 4.565 3.995 3.995Z"
        fill="currentColor"
      />
    </>
  ),
});


import { createIcon } from "@chakra-ui/react";

export const StatsIcon = createIcon({
  defaultProps: {
    fill: "none",
    height: "8",
    width: "5",
    stroke: "black",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
  viewBox: "0 0 24 25",
  path: (
    <path d="M12 6.5625C12.943 6.5625 13.414 6.5625 13.707 6.8555C14 7.1485 14 7.6195 14 8.5625V16.5625C14 17.5055 14 17.9765 13.707 18.2695C13.414 18.5625 12.943 18.5625 12 18.5625M12 6.5625C11.057 6.5625 10.586 6.5625 10.293 6.8555C10 7.1485 10 7.6195 10 8.5625V16.5625C10 17.5055 10 17.9765 10.293 18.2695C10.586 18.5625 11.057 18.5625 12 18.5625M12 6.5625V3.5625M12 18.5625V21.5625M19 5.5625C19.943 5.5625 20.414 5.5625 20.707 5.8555C21 6.1485 21 6.6195 21 7.5625V9.5625C21 10.5055 21 10.9765 20.707 11.2695C20.414 11.5625 19.943 11.5625 19 11.5625M19 5.5625C18.057 5.5625 17.586 5.5625 17.293 5.8555C17 6.1485 17 6.6195 17 7.5625V9.5625C17 10.5055 17 10.9765 17.293 11.2695C17.586 11.5625 18.057 11.5625 19 11.5625M19 5.5625V3.5625M19 11.5625V13.5625M5 10.5625C5.943 10.5625 6.414 10.5625 6.707 10.8555C7 11.1485 7 11.6195 7 12.5625V14.5625C7 15.5055 7 15.9765 6.707 16.2695C6.414 16.5625 5.943 16.5625 5 16.5625M5 10.5625C4.057 10.5625 3.586 10.5625 3.293 10.8555C3 11.1485 3 11.6195 3 12.5625V14.5625C3 15.5055 3 15.9765 3.293 16.2695C3.586 16.5625 4.057 16.5625 5 16.5625M5 10.5625V8.5625M5 16.5625V18.5625" />
  ),
});

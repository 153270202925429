import { ChakraProvider } from "@chakra-ui/react";
import { ReactNode } from "react";
import { ApolloProvider } from "./graphql/ApolloProvider";
import { theme } from "./components/styles/theme";

interface Props {
  children: ReactNode;
}

function AppProvider({ children }: Props) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <ApolloProvider uri={process.env.REACT_APP_API_URI}>
        {children}
      </ApolloProvider>
    </ChakraProvider>
  );
}
export default AppProvider;

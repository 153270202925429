import React from "react";
import { Box, Text, Icon, ComponentWithAs, IconProps } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export interface SideNavItemProps {
  DisplayIcon: ComponentWithAs<"svg", IconProps>;
  label: string;
  isActive?: boolean;
  hasSubMenu?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const SideNavItem: React.FC<SideNavItemProps> = ({
  DisplayIcon,
  label,
  isActive = false,
  hasSubMenu = false,
  onClick = () => {},
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={6}
      color={isActive ? "#0072DB" : "inherit"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <DisplayIcon boxSize={"24px"} color={isActive ? "#0072DB" : "inherit"} />
      <Text mt={2}>{label}</Text>
      {hasSubMenu && <Icon as={ChevronDownIcon} w={4} h={4} mt={2} />}
    </Box>
  );
};

import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "Front Desk",
  viewBox: "0 0 20 17",
  defaultProps: {
    fill: "none",
    height: "17",
    width: "20",
  },
  path: (
    <path
      d="M1 15.4336H19M10 4.43359V1.43359M10 4.43359H8C6.67392 4.43359 5.40215 4.96038 4.46447 5.89806C3.52678 6.83574 3 8.10751 3 9.43359V12.4336H17V9.43359C17 8.10751 16.4732 6.83574 15.5355 5.89806C14.5979 4.96038 13.3261 4.43359 12 4.43359H10ZM10 1.43359H8M10 1.43359H12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

import { CheckIcon } from "@chakra-ui/icons";
import { Box, Button, CloseButton, Divider, Text } from "@chakra-ui/react";
import { BellIcon } from "../../assets/icons/BellIcon";
import { Alert } from "../../graphql/__generated__/types";

interface AlertToastProps {
  alert: Alert;
  onClose: () => void;
  onAccept?: (invitationId: string, alertId: string) => Promise<void>;
}
export default function AlertToast({
  alert,
  onClose,
  onAccept,
}: AlertToastProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      minW="60vw"
      bg="#E7FDEF"
      border="1px solid #0DC94C"
      borderRadius={10}
      p={2}
    >
      <Box display="flex" gap={2} alignItems="center">
        {alert.extras?.alertType == "invitation" ? (
          <BellIcon color="#087D2F" mr={1} />
        ) : (
          <Box
            bg="#087D2F"
            borderRadius="50%"
            w={5}
            h={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={1}
          >
            <CheckIcon color="white" w={4} h={4} />
          </Box>
        )}
        <Text color="#087D2F" fontWeight={600}>
          {alert.title}
        </Text>
        <Text>{alert.description}</Text>
      </Box>
      <Box display="flex" gap={3} alignItems="center">
        {alert.extras?.alertType == "invitation" && onAccept && (
          <>
            <Button variant="none" fontWeight={100}>
              Decline
            </Button>
            <Button
              borderRadius={50}
              bg="#00438A2E"
              onClick={async () => {
                if (alert.extras?.invitationId) {
                  await onAccept(alert.extras?.invitationId, alert.id);
                  onClose();
                }
              }}
            >
              Accept
            </Button>
          </>
        )}
        <Divider orientation="vertical" borderWidth={1} h={8} />
        <CloseButton onClick={onClose} />
      </Box>
    </Box>
  );
}

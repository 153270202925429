/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetRoomsQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String']['input'];
}>;


export type GetRoomsQuery = { __typename: 'Query', rooms?: Array<{ __typename: 'Room', id: string, propertyId: string, roomId: string, checkInState: boolean, guestName?: string | null, isFake: boolean, createdAt: string, updatedAt: string, checkInDate?: string | null, checkOutDate?: string | null, guestLanguage?: Types.LanguageCode | null } | null> | null };


export const GetRoomsDocument = gql`
    query GetRooms($propertyId: String!) {
  rooms(propertyId: $propertyId) {
    id
    propertyId
    roomId
    checkInState
    guestName
    isFake
    createdAt
    updatedAt
    checkInDate
    checkOutDate
    guestLanguage
  }
}
    `;

/**
 * __useGetRoomsQuery__
 *
 * To run a query within a React component, call `useGetRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetRoomsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRoomsQuery, GetRoomsQueryVariables> & ({ variables: GetRoomsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRoomsQuery, GetRoomsQueryVariables>(GetRoomsDocument, options);
      }
export function useGetRoomsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoomsQuery, GetRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRoomsQuery, GetRoomsQueryVariables>(GetRoomsDocument, options);
        }
export function useGetRoomsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetRoomsQuery, GetRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetRoomsQuery, GetRoomsQueryVariables>(GetRoomsDocument, options);
        }
export type GetRoomsQueryHookResult = ReturnType<typeof useGetRoomsQuery>;
export type GetRoomsLazyQueryHookResult = ReturnType<typeof useGetRoomsLazyQuery>;
export type GetRoomsSuspenseQueryHookResult = ReturnType<typeof useGetRoomsSuspenseQuery>;
export type GetRoomsQueryResult = ApolloReactCommon.QueryResult<GetRoomsQuery, GetRoomsQueryVariables>;
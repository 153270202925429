import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { protect, redirectIfLoggedIn } from "./auth/Protected";
import { AllSites } from "./pages/AllSites";
import DevicesList from "./pages/DevicesList";
import { Layout } from "./pages/Layout";
import Hub from "./pages/Hub";

export function AppRoutes({}) {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: redirectIfLoggedIn(<LoginPage />),
    },
    {
      path: "*",
      element: protect(<Layout />),
      children: [
        {
          path: "",
          element: <Dashboard />,
        },
        {
          path: "sites",
          element: <AllSites />,
        },
        {
          path: "hub",
          element: <Hub />,
        },
        {
          path: "displays/:propertyId?",
          element: <DevicesList />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

import React, { ReactNode } from "react";
import { Box, Flex } from "@chakra-ui/react";

interface SideNavHeaderTemplateProps {
  header: ReactNode;
  sidenav?: ReactNode;
  children: ReactNode;
}

export const SideNavHeaderTemplate: React.FC<SideNavHeaderTemplateProps> = ({
  header,
  sidenav,
  children,
}) => {
  const headerHeight = "72px",
    sidenavWidth = sidenav ? "100px" : "0px";
  return (
    <Flex direction="column" height="100vh" width="100vw">
      <Box
        as="header"
        width="100%"
        height={headerHeight}
        position="fixed"
        top={0}
        left={0}
        zIndex={10}
      >
        {header}
      </Box>

      <Flex marginTop={headerHeight} flexGrow={1}>
        <Box
          as="nav"
          width={sidenavWidth}
          height={`calc(100vh - ${headerHeight})`}
          position="fixed"
          top={headerHeight}
          left={0}
          zIndex={1}
        >
          {sidenav}
        </Box>

        <Box
          marginLeft={sidenavWidth}
          width={`calc(100vw - ${sidenavWidth})`}
          height={`calc(100vh - ${headerHeight})`}
          overflow="auto"
          bgColor="rgba(244, 246, 248, 1)"
          position={"relative"}
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

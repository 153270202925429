import {
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import SiteLocation from "../../assets/site_location.png";
import { useSiteDetailsStore } from "../../store/newSiteCreate";
import { Form } from "react-router-dom";
import { getDemoData } from "../../store/demoDataStore";
import { useEffect } from "react";

export default function SiteDetails() {
  const { siteName, setSiteName } = useSiteDetailsStore();
  const demoData = getDemoData();

  useEffect(() => {
    setSiteName("Promessa");
  }, []);

  return (
    <VStack p={0} spacing={4} mb={2} align="left" as={Form}>
      <Text color="#0072DB" fontWeight="bold">
        Site Details
      </Text>
      <FloatingLabelInput
        placeholder="Site Name"
        value={siteName || demoData.siteName}
        onChange={setSiteName}
      />
      <FloatingLabelInput
        placeholder="Country"
        value="Netherlands"
        contentEditable={false}
      />
      <FloatingLabelInput
        placeholder="City"
        value="Utrecht"
        contentEditable={false}
      />
      <FloatingLabelInput
        placeholder="PIN"
        value="3512 JM"
        contentEditable={false}
      />
      <Text fontSize={14} ml={2}>
        Select Location
      </Text>
      <Image src={SiteLocation} p={0} />
    </VStack>
  );
}

interface FloatingLabelInputProps {
  placeholder: string;
  value: string;
  onChange?: (value: string) => void;
  contentEditable?: boolean;
}

const FloatingLabelInput = ({
  placeholder,
  value,
  onChange,
  contentEditable = true,
}: FloatingLabelInputProps) => {
  return (
    <FormControl variant="floating">
      <Input
        placeholder=" "
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        autoComplete="off"
        contentEditable={contentEditable}
      />
      <FormLabel>{placeholder}</FormLabel>
    </FormControl>
  );
};

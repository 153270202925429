import { gql } from "@apollo/client";
import {
  HStack,
  VStack,
  Box,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Spinner,
  IconButton,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import {
  useGetUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useResetDemoMutation,
} from "./__generated__/AdminDashboard.graphql";
import { useConditionalPolling } from "../hooks/useConditionalPolling";
import { POLL_INTERVAL } from "../config";
import { useAuthStore } from "../store/authUserStore";
import { DeleteIcon } from "@chakra-ui/icons";
import { AllowedAsset, UserType } from "../graphql/__generated__/types";
import { useGetSignedUrl } from "../hooks/getsignedUrl";
import { uploadToS3 } from "../utils/uploadToS3";
import { usersTobeCreated } from "../demoData/demoUsersData";

// const generateUserDetailsFromUserType = (userType: UserType) => {
//   return {
//     name: userType.replace("_", " ").toTitleCase(),
//     email: `${userType}@tpv-tech.com`,
//     userType: userType,
//     password: "password",
//     confirmPassword: "password",
//   };
// };

const AdminDashboard = () => {
  const toast = useToast();
  const { isAuthenticated } = useAuthStore();
  const cloneFileInputRef = useRef<HTMLInputElement>(null);
  const diffCloneFileInputRef = useRef<HTMLInputElement>(null);

  const [cloneFile, setCloneFile] = useState<File | null>(null);
  const [diffCloneFile, setDiffCloneFile] = useState<File | null>(null);
  const [cloneFileName, setCloneFileName] = useState<string>("");
  const [diffCloneFileName, setDiffCloneFileName] = useState<string>("");
  const [currentDeletingUsers, setCurrentDeletingUsers] = useState<string[]>(
    []
  );
  const [currentUpdatingUsers, setCurrentUpdatingUsers] = useState<string[]>(
    []
  );
  const [uploadingCloneFilesType, setUploadingCloneFilesType] = useState<
    AllowedAsset[]
  >([]);
  const [creatingDemoUsers, setCreatingDemoUsers] = useState<boolean>(false);

  const createUserModal = useDisclosure();
  const resetDemoModal = useDisclosure();

  const { data, loading, startPolling, stopPolling } = useGetUsersQuery({
    fetchPolicy: "network-only",
    skip: !isAuthenticated,
  });
  const [deleteUser] = useDeleteUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const { getSignedUrl } = useGetSignedUrl();
  const [createUser] = useCreateUserMutation();

  const handleCloneFileUpdload = async (assetType: AllowedAsset) => {
    setUploadingCloneFilesType([...uploadingCloneFilesType, assetType]);
    const cloneFileToUpload =
      assetType === AllowedAsset.DiffCloneFile ? diffCloneFile : cloneFile;
    const cloneFileToUploadName =
      assetType === AllowedAsset.DiffCloneFile
        ? diffCloneFileName
        : cloneFileName;
    if (!cloneFileToUpload)
      return toast({
        title: "Invalid upload",
        description: "Please select a file to upload first.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    const response = await getSignedUrl(
      cloneFileToUploadName,
      cloneFileToUpload.type,
      assetType
    );
    try {
      await uploadToS3(response.signedUrl, cloneFileToUpload);
      toast({
        title: "Clone File Upload",
        description: "File uploaded successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Clone File Upload",
        description:
          "Error while uploading the clone file. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploadingCloneFilesType(
        uploadingCloneFilesType.filter((fileType) => fileType !== assetType)
      );
    }
  };

  useConditionalPolling({
    startPolling,
    stopPolling,
    pollInterval: POLL_INTERVAL,
  });

  const handleCloneFileButtonClick = () => {
    cloneFileInputRef.current?.click();
  };

  const handleCloneFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setCloneFileName(file.name);
      setCloneFile(file);
    }
  };

  const handleDemoUsersCreate = async () => {
    setCreatingDemoUsers(true);
    Promise.all(
      usersTobeCreated.map((user) => {
        createUser({
          variables: {
            ...user,
            email: user.email.toLowerCase(),
          },
          refetchQueries: [
            {
              query: AdminDashboard.graphql.queries,
            },
          ],
          awaitRefetchQueries: true,
        });
      })
    )
      .then(() => {
        toast({
          title: "Users Created",
          description: "Demo users created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setCreatingDemoUsers(false);
      })
      .catch(() => {
        toast({
          title: "Error Creating Users",
          description:
            "There was an error while creating users. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setCreatingDemoUsers(false);
      });
  };

  const handleDiffCloneFileButtonClick = () => {
    diffCloneFileInputRef.current?.click();
  };

  const handleDiffCloneFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setDiffCloneFileName(file.name);
      setDiffCloneFile(file);
    }
  };

  const handleDeleteUser = async (id: string) => {
    if (currentDeletingUsers.includes(id)) return;
    try {
      setCurrentDeletingUsers([...currentDeletingUsers, id]);
      await deleteUser({
        variables: { id },
        optimisticResponse: { __typename: "Mutation", deleteUser: true },
        refetchQueries: [
          {
            query: AdminDashboard.graphql.queries,
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentDeletingUsers(
        currentDeletingUsers.filter((userId) => userId !== id)
      );
    }
  };

  const handleUpdateUser = async (id: string, userType: UserType) => {
    try {
      if (currentUpdatingUsers.includes(id)) return;
      setCurrentUpdatingUsers([...currentUpdatingUsers, id]);
      await updateUser({
        variables: {
          id,
          userType,
        },
        refetchQueries: [
          {
            query: AdminDashboard.graphql.queries,
          },
        ],
        awaitRefetchQueries: true,
        onError: (error) => console.log("Got error: ", error),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentUpdatingUsers(
        currentUpdatingUsers.filter((userId) => userId !== id)
      );
    }
  };

  return (
    <Box bgColor="white" height={"100%"} paddingX="10%" overflowY="scroll">
      <VStack marginBottom={"40px"}>
        <Heading as="h2" size={"lg"} marginTop={"40px"}>
          Demo Support Dashboard
        </Heading>

        <HStack
          width={"100%"}
          justifyContent={"space-between"}
          marginTop="30px"
        >
          <Heading as="h2" size="md">
            User Management
          </Heading>
          <Button onClick={resetDemoModal.onOpen}>Reset Demo</Button>
        </HStack>

        {loading ? (
          <Box height="200px" alignContent="center">
            <Spinner />
          </Box>
        ) : (
          <TableContainer width="100%" marginTop="30px">
            <Table variant={"striped"} colorScheme="rgba(244, 246, 248, 1)">
              <Thead>
                <Tr>
                  <Th>Id</Th>
                  <Th>User name</Th>
                  <Th>Email</Th>
                  <Th minWidth={"230px"}>Role</Th>
                  <Th></Th>
                </Tr>
                {data?.users
                  ?.filter(
                    (user) => user?.email !== "backup-admin@tpv-tech.com"
                  )
                  .sort((user1, user2) =>
                    (user1?.id as string).localeCompare(user2?.id as string)
                  )
                  .map((user) => (
                    <Tr key={user?.id}>
                      <Td>{user?.id}</Td>
                      <Td>{user?.name}</Td>
                      <Td>{user?.email}</Td>
                      <Td>
                        {currentUpdatingUsers.includes(user?.id as string) ? (
                          <Spinner />
                        ) : (
                          <Select
                            disabled={user?.userType === "admin"}
                            value={user?.userType}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                              handleUpdateUser(
                                user?.id as string,
                                e.target.value as UserType
                              )
                            }
                          >
                            {Object.values(UserType).map((userType) => (
                              <option key={userType} value={userType}>
                                {userType}
                              </option>
                            ))}
                          </Select>
                        )}
                      </Td>
                      <Td>
                        {user?.userType !== "admin" &&
                          (currentDeletingUsers.includes(user?.id as string) ? (
                            <Spinner />
                          ) : (
                            <IconButton
                              aria-label="Delete User"
                              icon={<DeleteIcon height={"15px"} />}
                              height={"20px"}
                              onClick={() =>
                                handleDeleteUser(user?.id as string)
                              }
                            />
                          ))}
                      </Td>
                    </Tr>
                  ))}
              </Thead>
            </Table>
          </TableContainer>
        )}
        <HStack width={"100%"} justifyContent={"center"} marginTop="30px">
          <Button marginTop={"10px"} onClick={handleDemoUsersCreate}>
            Create Demo Users
          </Button>
          <Button marginTop={"10px"} onClick={createUserModal.onOpen}>
            Create User
          </Button>
        </HStack>

        <Heading as="h2" size="md" marginTop="50px" width={"100%"}>
          Clone
        </Heading>

        <Heading as="h2" size="sm" marginTop={"15px"} width={"100%"}>
          Clone File - ProfessionalData-V1.0
        </Heading>
        <HStack justifyContent="left" width="100%">
          <Input value={cloneFileName} />
          <div>
            <Input
              type="file"
              display={"none"}
              ref={cloneFileInputRef}
              onChange={handleCloneFileChange}
            />
            <Button onClick={handleCloneFileButtonClick}>Browse</Button>
          </div>
          <div>
            <Button
              onClick={() => handleCloneFileUpdload(AllowedAsset.CloneFile)}
            >
              {uploadingCloneFilesType.includes(AllowedAsset.CloneFile) ? (
                <Spinner />
              ) : (
                "Upload"
              )}
            </Button>
          </div>
        </HStack>

        <Heading as="h2" size="sm" marginTop={"15px"} width={"100%"}>
          Diff Clone File - ProfessionalData-V2.0
        </Heading>
        <HStack justifyContent="left" width="100%">
          <Input value={diffCloneFileName} />
          <div>
            <Input
              type="file"
              display={"none"}
              ref={diffCloneFileInputRef}
              onChange={handleDiffCloneFileChange}
            />
            <Button onClick={handleDiffCloneFileButtonClick}>Browse</Button>
          </div>
          <div>
            <Button
              onClick={() => handleCloneFileUpdload(AllowedAsset.DiffCloneFile)}
            >
              {uploadingCloneFilesType.includes(AllowedAsset.DiffCloneFile) ? (
                <Spinner />
              ) : (
                "Upload"
              )}
            </Button>
          </div>
        </HStack>
        <CreateUser
          isOpen={createUserModal.isOpen}
          onClose={createUserModal.onClose}
        />
        <RestDemo
          isOpen={resetDemoModal.isOpen}
          onClose={resetDemoModal.onClose}
        />
      </VStack>
    </Box>
  );
};

interface ResetDemoProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RestDemo = ({ isOpen, onClose }: ResetDemoProps) => {
  const [resetConfirm, setResetConfirm] = useState({
    resetConfirmString: "",
    resetConfirmError: "",
  });
  const [resetDemo, { loading: resetDemoLoading }] = useResetDemoMutation();

  const handleReset = async () => {
    if (resetConfirm.resetConfirmString !== "reset")
      return setResetConfirm({
        ...resetConfirm,
        resetConfirmError: "Type 'reset' to continue.",
      });

    try {
      await resetDemo({
        refetchQueries: [
          {
            query: AdminDashboard.graphql.queries,
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent alignSelf={"center"}>
        <ModalCloseButton />
        <ModalHeader
          fontWeight={"bold"}
          textAlign={"center"}
          marginTop={"40px"}
        >
          Confirm Reset Demo
        </ModalHeader>
        <ModalBody>
          <VStack marginBottom={"40px"} padding={"15px"}>
            <FormControl isInvalid={!!resetConfirm.resetConfirmError}>
              <Input
                placeholder="Type 'reset' to reset demo."
                marginTop={"20px"}
                required
                id="resetString"
                value={resetConfirm.resetConfirmString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setResetConfirm({
                    ...resetConfirm,
                    resetConfirmString: e.target.value,
                  })
                }
                onFocus={() =>
                  setResetConfirm({ ...resetConfirm, resetConfirmError: "" })
                }
              />
              <FormErrorMessage>
                {resetConfirm.resetConfirmError}
              </FormErrorMessage>
            </FormControl>

            {resetDemoLoading ? (
              <Spinner marginTop="20px" />
            ) : (
              <Button type="submit" marginTop="30px" onClick={handleReset}>
                Submit
              </Button>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface CreateUserProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateUser = ({ isOpen, onClose }: CreateUserProps) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    displayPicture: null,
    userType: "",
    password: "",
    confirmPassword: "",
  });
  const [createUserType, setCreateUserType] = useState<string>("");
  const [createUser, { loading: userCreateLoading }] = useCreateUserMutation();

  const handleUserDetailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let changeDetails: Record<string, string> = {};
    if (e.target.id === "userTypeCreate") {
      setCreateUserType(e.target.value);
      const userType = e.target.value;
      changeDetails =
        usersTobeCreated.find((user) => user.userType == userType) || {};
      if (changeDetails.email) {
        changeDetails.email = changeDetails.email.toLowerCase();
      }
    } else {
      changeDetails = { [e.target.id]: e.target.value };
    }
    setUserDetails({ ...userDetails, ...changeDetails });
  };

  const handleSubmit = async () => {
    try {
      await createUser({
        variables: {
          ...userDetails,
          userType: userDetails.userType as UserType,
        },
        refetchQueries: [
          {
            query: AdminDashboard.graphql.queries,
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent alignSelf={"center"}>
        <ModalCloseButton />
        <ModalHeader
          fontWeight={"bold"}
          textAlign={"center"}
          marginTop={"40px"}
        >
          Create User
        </ModalHeader>
        <ModalBody>
          <VStack marginBottom={"40px"} padding={"15px"}>
            <Select
              placeholder="Create User Of Type"
              marginTop={"20px"}
              required
              id="userTypeCreate"
              value={createUserType}
              onChange={handleUserDetailChange}
            >
              {Object.values(UserType)
                .filter((userType) => userType !== "admin")
                .map((userType) => (
                  <option key={userType} value={userType}>
                    {userType}
                  </option>
                ))}
            </Select>
            <Input
              placeholder="Full Name"
              marginTop={"20px"}
              required
              id="name"
              value={userDetails.name}
              onChange={handleUserDetailChange}
            />
            <Input
              placeholder="Email"
              marginTop={"20px"}
              type="email"
              required
              id="email"
              value={userDetails.email}
              onChange={handleUserDetailChange}
            />
            <Select
              placeholder="Select User Type"
              marginTop={"20px"}
              required
              id="userType"
              value={userDetails.userType}
              onChange={handleUserDetailChange}
            >
              {Object.values(UserType)
                .filter((userType) => userType !== "admin")
                .map((userType) => (
                  <option key={userType} value={userType}>
                    {userType}
                  </option>
                ))}
            </Select>
            <Input
              placeholder="Password"
              marginTop={"20px"}
              type="password"
              required
              id="password"
              value={userDetails.password}
              onChange={handleUserDetailChange}
            />
            <Input
              placeholder="Confirm Password"
              marginTop={"20px"}
              type="password"
              required
              id="confirmPassword"
              value={userDetails.confirmPassword}
              onChange={handleUserDetailChange}
            />
            {userCreateLoading ? (
              <Spinner marginTop="20px" />
            ) : (
              <Button type="submit" marginTop="30px" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

AdminDashboard.graphql = {
  mutations: {
    createUser: gql`
      mutation CreateUser(
        $name: String!
        $email: String!
        $userType: UserType!
        $displayPicture: String
        $password: String!
        $confirmPassword: String!
      ) {
        createUser(
          name: $name
          email: $email
          displayPicture: $displayPicture
          userType: $userType
          password: $password
          confirmPassword: $confirmPassword
        ) {
          user {
            id
            name
            email
            displayPicture
            propertyId
            userType
            createdAt
            updatedAt
          }
          tokens {
            accessToken
          }
        }
      }
    `,
    deleteUser: gql`
      mutation DeleteUser($id: String!) {
        deleteUser(id: $id)
      }
    `,
    updateUser: gql`
      mutation UpdateUser(
        $id: String!
        $name: String
        $displayPicture: String
        $userType: UserType
      ) {
        updateUser(
          id: $id
          name: $name
          displayPicture: $displayPicture
          userType: $userType
        ) {
          id
          name
          email
          displayPicture
          propertyId
          userType
          createdAt
          updatedAt
        }
      }
    `,
    resetDemo: gql`
      mutation ResetDemo {
        resetDemo
      }
    `,
  },
  queries: gql`
    query getUsers {
      users {
        id
        name
        email
        displayPicture
        propertyId
        userType
        createdAt
        updatedAt
      }
    }
  `,
};

export default AdminDashboard;

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider as RawApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ReactNode, useMemo } from "react";
import { useAuthStore } from "../store/authUserStore";
import { useToast } from "@chakra-ui/react";
import { customErrorCodes } from "./errors";

interface Props {
  uri: string | undefined;
  children: ReactNode;
}

export function ApolloProvider({ uri, children }: Props) {
  const { isAuthenticated, token, logout } = useAuthStore();
  const toast = useToast();

  const getErrorTitle = (statusCode: number) => {
    const error = customErrorCodes[statusCode as keyof typeof customErrorCodes];
    return error ? error.replace(/_/g, " ").toUpperCase() : "ERROR OCCURRED";
  };

  const authLink = useMemo(() => {
    return setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: isAuthenticated && token ? `Bearer ${token}` : "",
        },
      };
    });
  }, [isAuthenticated, token]);

  const httpLink = useMemo(() => {
    return new HttpLink({
      uri,
    });
  }, [uri]);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let errorMessage;
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        errorMessage = message;
      });

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      if ("statusCode" in networkError) {
        if (networkError.statusCode == 401) {
          logout();
          toast.closeAll();
        }
        toast({
          title: getErrorTitle(networkError.statusCode),
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  });

  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
        },
      },
      link: authLink.concat(errorLink).concat(httpLink),
    });
  }, [authLink, httpLink]);

  return <RawApolloProvider client={client}>{children}</RawApolloProvider>;
}

export function getRawApolloClient(
  uri: string | undefined,
  headers: Record<string, string>
) {
  return new ApolloClient({
    uri: uri,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
      },
    },
    headers: headers,
  });
}

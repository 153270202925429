import { createIcon } from "@chakra-ui/react";

export const LocationIcon = createIcon({
  defaultProps: {
    width: "21",
    height: "21",
    fill: "none",
  },
  viewBox: "0 0 21 21",
  path: (
    <path
      d="M20.0001 0.996094L13.5001 18.9961C13.4562 19.0918 13.3858 19.173 13.2971 19.2298C13.2085 19.2867 13.1054 19.317 13.0001 19.317C12.8948 19.317 12.7917 19.2867 12.703 19.2298C12.6144 19.173 12.5439 19.0918 12.5001 18.9961L9.00007 11.9961L2.00007 8.49609C1.90433 8.45222 1.8232 8.38178 1.76632 8.29315C1.70944 8.20451 1.6792 8.10141 1.6792 7.99609C1.6792 7.89078 1.70944 7.78768 1.76632 7.69904C1.8232 7.61041 1.90433 7.53997 2.00007 7.49609L20.0001 0.996094Z"
      stroke="#0072DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

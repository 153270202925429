import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  useToast,
  Text,
} from "@chakra-ui/react";
import Alerts from "./Alert/Alerts";
import { HelpIcon } from "../assets/icons/HelpIcon";
import SearchBar from "./SearchBar";
import HtvHeaderLogo from "../assets/htv_header_logo.png";
import { useAuthStore } from "../store/authUserStore";
import { useNavigate } from "react-router-dom";

export function Header() {
  const { logout, user } = useAuthStore();
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <>
      <Box width="100%" height="100%" boxShadow="0px 4px 4px rgba(0,0,0,0.25)">
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={2}
        >
          <Image
            src={HtvHeaderLogo}
            w={150}
            cursor="pointer"
            onClick={() => {
              navigate("/");
            }}
          />
          {user?.userType !== "admin" && (
            <Box height={"36px"} width={"520px"}>
              <SearchBar />
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Alerts />
            {user?.userType !== "admin" && (
              <IconButton
                icon={<HelpIcon />}
                aria-label="Help"
                variant="ghost"
                mr={2}
              />
            )}
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                sx={{
                  backgroundColor: "transparent",
                  _hover: { backgroundColor: "transparent" },
                  _active: { backgroundColor: "transparent" },
                  _focus: { boxShadow: "none" },
                }}
              >
                <Text>{user?.name}</Text>
                {user?.userType != "admin" && (
                  <Text mt={1}>
                    {user?.userType
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </Text>
                )}
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    logout();
                    toast.closeAll();
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </Box>
    </>
  );
}

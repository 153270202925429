import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "CustomIcon9",
  viewBox: "0 0 24 25",
  defaultProps: {
    color: "black", // Default color is set to black
  },
  path: (
    <>
      <path
        d="M10 22.4336V15.8636M12 11.4336H12.01M12 7.43359H12.01M14 15.8636V22.4336M15 16.4336C14.1345 15.7845 13.0819 15.4336 12 15.4336C10.9181 15.4336 9.86548 15.7845 9 16.4336M16 11.4336H16.01M16 7.43359H16.01M8 11.4336H8.01M8 7.43359H8.01"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18 2.43359H6C4.89543 2.43359 4 3.32902 4 4.43359V20.4336C4 21.5382 4.89543 22.4336 6 22.4336H18C19.1046 22.4336 20 21.5382 20 20.4336V4.43359C20 3.32902 19.1046 2.43359 18 2.43359Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
});

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import DefaultSiteImage from "../assets/default_siteimage.png";
import DefaultSiteLogo from "../assets/default_sitelogo.png";
import ActiveWaveDisplays from "../assets/dm_dash_active_displays.png";
import DisplayFirmwareImg from "../assets/dm_dash_firmwares.png";
import DisplaysWithLatestConfigImg from "../assets/dm_dash_lastest_config_displays.png";
import LicenesImg from "../assets/dm_dash_licenses.png";
import OffDisplays from "../assets/dm_dash_off_displays.png";
import OfflineWaveDisplays from "../assets/dm_dash_offline_wave_displays.png";
import StatusImg from "../assets/dm_dash_status.png";
import { LocationIcon } from "../assets/icons/LocationIcon";
import { PropertyWithTVs } from "../graphql/__generated__/types";
import { useGetPropertiesQuery } from "../pages/__generated__/AllSites.graphql";
import PagePadding from "./PagePadding";
import SolarTVIcon from "../assets/icons/SolarTVIcon";

export function DeviceManagerDashboard() {
  const { data: propertiesData } = useGetPropertiesQuery();

  return (
    <PagePadding height="calc(100vh - 132px)">
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gridTemplateRows="42vh 42vh"
        gap={4}
        p={0}
        height="100%"
      >
        <GridItem colSpan={3}>
          <Box display="flex" w="100%" h="100%">
            <Box
              overflow="auto"
              bgColor="white"
              p={5}
              pr={0}
              borderRadius={12}
              boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
              w="100%"
              display="flex"
              flexDir="column"
              justifyContent="space-between"
            >
              <Text fontWeight="bold" fontSize={20}>
                New Sites
              </Text>
              <Box overflowX="hidden" maxW="100%" h="300px">
                <HStack spacing={3} minWidth="max-content">
                  {propertiesData?.properties?.length &&
                    propertiesData?.properties
                      .slice(0, 3)
                      .map(
                        (property) =>
                          property && (
                            <PropertyCard
                              property={property}
                              key={property.id}
                            />
                          )
                      )}
                </HStack>
              </Box>
            </Box>
          </Box>
        </GridItem>
        <GridItem>
          <StatusCard />
        </GridItem>
        <GridItem
          rowSpan={2}
          display="flex"
          flexDir="column"
          gap={4}
          overflowY="auto"
        >
          <DisplaysInfoCard
            count="185"
            text="Displays ON"
            image={ActiveWaveDisplays}
          />
          <DisplaysInfoCard
            count="650"
            text="Displays OFF"
            image={OffDisplays}
          />
          <DisplaysInfoCard
            count="05"
            text="Offline wave displays"
            image={OfflineWaveDisplays}
          />
          <DisplaysWithOlderConfig
            count="24"
            text="Displays with older config"
          />
          <DisplaysWithLatestConfig
            count="48"
            text="Displays with latest config"
            image={DisplaysWithLatestConfigImg}
          />
        </GridItem>
        <GridItem>
          <DisplayFirmwareCard />
        </GridItem>
        <GridItem colSpan={3} h="100%">
          <LicensesCard />
        </GridItem>
      </Grid>
    </PagePadding>
  );
}

interface PropertyCardProps {
  property: PropertyWithTVs;
}

function PropertyCard({ property }: PropertyCardProps) {
  const navigate = useNavigate();
  return (
    <Card
      gap={4}
      variant="none"
      p={0}
      size="sm"
      cursor="pointer"
      onClick={() =>
        !property.isFake &&
        navigate(`/displays/${property.id}`, { state: property })
      }
    >
      <CardBody>
        <Box mb={3}>
          <Image
            src={property?.imageUrl || DefaultSiteImage}
            w={350}
            h={200}
            fit="cover"
            borderRadius={10}
          />
        </Box>
        <HStack justifyContent="space-between" w={350} maxW="100%" p={1}>
          <Image
            src={property?.logoUrl || DefaultSiteLogo}
            borderRadius={100}
            h={50}
            w={50}
            fit="cover"
          />
          <HStack justifyContent="space-between" width="100%">
            <Box>
              <Text fontWeight={600}>{property?.name}</Text>
              <Text fontSize="xs">
                Added by: {property?.createdByUser?.name}
              </Text>
            </Box>
            <LocationIcon />
          </HStack>
          <Box borderLeft="1.5px solid gray" ml={4} mr={2} h={8} />
          <Box textAlign="center">
            <Text fontWeight="500" fontSize={20}>
              {(property?.tvs && property?.tvs.length) ||
                property.displaysClaimed ||
                "0"}
            </Text>
            <Text fontSize={12}>
              {property?.tvs && property?.tvs.length == 1
                ? "Display"
                : "Displays"}
            </Text>
          </Box>
        </HStack>
      </CardBody>
    </Card>
  );
}

interface DisplaysInfoCardProps {
  count: string;
  text: string;
  image?: string;
}
function DisplaysInfoCard({ count, text, image }: DisplaysInfoCardProps) {
  return (
    <Card>
      <CardBody
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        minH="250px"
      >
        <VStack gap={3} align="left">
          <Text fontSize={40} fontWeight={600}>
            {count}
          </Text>
          <Text color="#757575" fontSize="17.3px">
            {text}
          </Text>
          <Text color="#0072DB" fontSize={12} textDecoration="underline">
            View Details
          </Text>
        </VStack>
        <Image
          src={image}
          position="absolute"
          top="0"
          right="0"
          maxW="193.21px"
          maxH="128.8px"
        />
      </CardBody>
    </Card>
  );
}

function DisplaysWithLatestConfig({
  count,
  text,
  image,
}: DisplaysInfoCardProps) {
  return (
    <Card p={2}>
      <CardBody
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        minH="300px"
      >
        <VStack gap={3} align="end">
          <Text fontSize={40} fontWeight={600}>
            {count}
          </Text>
          <Text color="#757575" fontSize="17.3px">
            {text}
          </Text>
          <Text color="#0072DB" fontSize={12} textDecoration="underline">
            View Details
          </Text>
        </VStack>
        <Image
          src={image}
          position="absolute"
          top="3"
          left="3"
          maxW="200px"
          maxH="220px"
        />
      </CardBody>
    </Card>
  );
}

function DisplaysWithOlderConfig({ count, text }: DisplaysInfoCardProps) {
  return (
    <Card p={2}>
      <CardBody
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        minH="200px"
      >
        <VStack gap={3} align="left">
          <Box display="flex" alignItems="center">
            <SolarTVIcon />
          </Box>
          <Text fontSize={40} fontWeight={600}>
            {count}
          </Text>
          <Text color="#757575" fontSize="17.3px">
            {text}
          </Text>
          <Text color="#0072DB" fontSize={12} textDecoration="underline">
            View Details
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}

const DisplayFirmwareCard = () => (
  <Card h="100%">
    <CardHeader>
      <Text fontWeight={600} fontSize={20}>
        Display Firmwares
      </Text>
    </CardHeader>
    <CardBody
      p={10}
      pt={5}
      display="flex"
      flexDir="column"
      justifyContent="space-around"
    >
      <Image src={DisplayFirmwareImg} />
      <HStack justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <Box bgColor="#DD0000" h={5} w={5} borderRadius={4}></Box>
          <Text fontSize={14}>Latest Version</Text>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Box bgColor="#0072DB" h={5} w={5} borderRadius={4}></Box>
          <Text fontSize={14}>Older Version</Text>
        </Box>
      </HStack>
    </CardBody>
  </Card>
);

const StatusCard = () => (
  <Card h="100%">
    <CardHeader>
      <Text fontWeight={600} fontSize={20}>
        Status
      </Text>
    </CardHeader>
    <CardBody display="flex" flexDir="column" justifyContent="space-around">
      <Image src={StatusImg} />
      <VStack justifyContent="space-between" divider={<Divider />}>
        {[
          {
            txt: "Clone updated",
            num: 784,
            col: "#0072DB",
          },
          {
            txt: "Clone Failed",
            num: 11,
            col: "#ED022A",
          },
          {
            txt: "Clone updating",
            num: 45,
            col: "#F2A40E",
          },
        ].map((item) => (
          <Box
            key={item.txt}
            display="flex"
            alignItems="center"
            w="100%"
            justifyContent="space-between"
          >
            <Box display="flex" gap={5} alignItems="center">
              <Box bgColor={item.col} h={5} w={5} borderRadius={4}></Box>
              <Text fontSize={14}>{item.txt}</Text>
            </Box>
            <Text fontSize={20} fontWeight={600}>
              {item.num}
            </Text>
          </Box>
        ))}
      </VStack>
    </CardBody>
  </Card>
);

const LicensesCard = () => (
  <Card h="100%">
    <CardHeader>
      <Text fontWeight={600} fontSize={20}>
        Licenses
      </Text>
    </CardHeader>
    <CardBody display="flex" flexDir="column" justifyContent="space-around">
      <Image src={LicenesImg} h="100%" w="100%" />
    </CardBody>
  </Card>
);

import { gql } from "@apollo/client";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ClockIcon from "../../assets/icons/ClockIcon";
import { LanguageCode } from "../../graphql/__generated__/types";
import { useGuestCheckInMutation } from "./__generated__/CheckIn.graphql";

interface CheckInProps {
  roomIds?: string | string[];
  isOpen: boolean;
  onClose: () => void;
}

const languageOptions = [
  { code: LanguageCode.Eng, label: "English" },
  { code: LanguageCode.Deu, label: "German" },
  { code: LanguageCode.Fra, label: "French" },
  { code: LanguageCode.Ita, label: "Italian" },
  { code: LanguageCode.Nld, label: "Dutch" },
  { code: LanguageCode.Spa, label: "Spanish" },
];

export default function CheckIn({ roomIds, isOpen, onClose }: CheckInProps) {
  const toast = useToast();
  const [checkOutDate, setCheckOutDate] = useState<Date>(new Date());
  const [checkOutTime, setCheckOutTime] = useState<Date>(new Date());
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [firstname, setFirstname] = useState("Olivier");
  const [lastname, setLastname] = useState("Dubois");
  const [selectedRoomId, setSelectedRoomId] = useState<string>("");
  const [guestLanguage, setGuestLanguage] = useState<LanguageCode | undefined>(
    LanguageCode.Fra
  );
  const [guestCheckIn, { loading }] = useGuestCheckInMutation();

  const combineDateAndTime = (date: Date, time: Date): Date => {
    const combined = new Date(date);
    combined.setHours(time.getHours());
    combined.setMinutes(time.getMinutes());
    combined.setSeconds(time.getSeconds());
    combined.setMilliseconds(time.getMilliseconds());
    return combined;
  };

  useEffect(() => {
    if (roomIds) {
      if (Array.isArray(roomIds)) {
        if (roomIds.length > 0) {
          setSelectedRoomId(roomIds[0]);
        }
      } else {
        setSelectedRoomId(roomIds);
      }
    }
  }, [roomIds]);

  const handleCheckIn = async () => {
    if (isCheckedIn) return;
    if (!firstname.length || !lastname.length || !guestLanguage) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const combinedCheckOutDate = combineDateAndTime(checkOutDate, checkOutTime);
    try {
      await guestCheckIn({
        variables: {
          checkInDate: new Date().toISOString(),
          checkOutDate: combinedCheckOutDate.toISOString(),
          roomId: selectedRoomId,
          guestName: `${firstname} ${lastname}`,
          guestLanguage: guestLanguage,
        },
      });
      setIsCheckedIn(true);
    } catch {
      setIsCheckedIn(false);
    }
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton />
        <DrawerHeader paddingBottom={2} fontWeight="bold">
          Check In
          <Divider mt={3} />
        </DrawerHeader>
        <DrawerBody
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
        >
          <VStack align="start" spacing={5}>
            <Text fontWeight="600">Guest Room</Text>
            {Array.isArray(roomIds) ? (
              <Select
                onChange={(e) => setSelectedRoomId(e.target.value)}
                value={selectedRoomId}
              >
                {roomIds.map((room) => (
                  <option key={room} value={room}>
                    {room}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>{roomIds}</Text>
            )}
            <Text fontWeight="600">Guest Details</Text>
            <Input
              placeholder="First name"
              onChange={(e) => setFirstname(e.target.value)}
              value={firstname}
            />
            <Input
              placeholder="Last name"
              onChange={(e) => setLastname(e.target.value)}
              value={lastname}
            />
            <Text fontWeight="600">Check out date</Text>
            <DatePicker
              wrapperClassName="react-custon-date-picker"
              selected={checkOutTime}
              onChange={(date: Date | null) => date && setCheckOutTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              customInput={<CustomInput icon={<ClockIcon />} />}
              dateFormat="hh:mm aa"
            />
            <DatePicker
              wrapperClassName="react-custon-date-picker"
              selected={checkOutDate}
              onChange={(date: Date | null) => date && setCheckOutDate(date)}
              customInput={<CustomInput icon={<CalendarIcon />} />}
              dateFormat="do MMMM yyyy"
            />
            <Text fontWeight="600">Language</Text>
            <Select
              value={guestLanguage}
              onChange={(e) => setGuestLanguage(e.target.value as LanguageCode)}
              placeholder="Language"
            >
              {languageOptions.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </Select>
          </VStack>
          <Button
            backgroundColor={isCheckedIn ? "#41B546" : "#0072DB"}
            w="100%"
            fontWeight="100"
            color="white"
            mb={10}
            onClick={handleCheckIn}
            isLoading={loading}
          >
            {isCheckedIn && <CheckIcon color="white" w={3} h={3} mr={2} />}
            {isCheckedIn ? "Checked In" : "Check In"}
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

const CustomInput = forwardRef<
  HTMLInputElement,
  { value?: string; onClick?: () => void; icon: React.ReactElement }
>(({ value, onClick, icon }, ref) => (
  <InputGroup>
    <Input
      onClick={onClick}
      value={value}
      readOnly
      placeholder="Check out date"
      ref={ref}
    />
    <InputRightElement>
      <IconButton
        aria-label="Select date"
        icon={icon}
        onClick={onClick}
        variant="ghost"
        size="sm"
      />
    </InputRightElement>
  </InputGroup>
));
CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element.isRequired,
};
CustomInput.displayName = "CustomInput";

CheckIn.graphql = {
  mutations: {
    GuestCheckIn: gql`
      mutation GuestCheckIn(
        $roomId: String!
        $guestName: String!
        $checkInDate: String!
        $checkOutDate: String!
        $guestLanguage: LanguageCode!
      ) {
        guestCheckIn(
          roomId: $roomId
          guestName: $guestName
          checkOutDate: $checkOutDate
          checkInDate: $checkInDate
          guestLanguage: $guestLanguage
        ) {
          id
        }
      }
    `,
  },
};

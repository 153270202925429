/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetPropertiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPropertiesQuery = { __typename: 'Query', properties?: Array<{ __typename: 'PropertyWithTVs', id: string, name: string, description?: string | null, logoUrl?: string | null, imageUrl?: string | null, chain?: string | null, brand?: string | null, solutionProviderName?: string | null, solutionProviderEmail?: string | null, contentManagerName?: string | null, contentManagerEmail?: string | null, displaysClaimed?: string | null, proLicenseCount?: string | null, liteLicenseCount?: string | null, country?: string | null, city?: string | null, overviewStatus?: string | null, isFake: boolean, createdBy: string, createdAt: string, updatedAt: string, tvs?: Array<{ __typename: 'TV', id: string, thingId: string, model: string, roomId?: string | null, propertyId?: string | null, claimQRCode?: string | null, claimState?: Types.ClaimState | null, cloneState?: Types.CloneState | null, cloneProgress?: number | null, isFake: boolean, createdAt: string, updatedAt: string } | null> | null, createdByUser?: { __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string } | null, propertyUsers?: Array<{ __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string } | null> | null } | null> | null };


export const GetPropertiesDocument = gql`
    query GetProperties {
  properties {
    id
    name
    description
    logoUrl
    imageUrl
    chain
    brand
    solutionProviderName
    solutionProviderEmail
    contentManagerName
    contentManagerEmail
    displaysClaimed
    proLicenseCount
    liteLicenseCount
    country
    city
    overviewStatus
    tvs {
      id
      thingId
      model
      roomId
      propertyId
      claimQRCode
      claimState
      cloneState
      cloneProgress
      isFake
      createdAt
      updatedAt
    }
    isFake
    createdBy
    createdByUser {
      id
      name
      email
      displayPicture
      propertyId
      userType
      createdAt
      updatedAt
    }
    propertyUsers {
      id
      name
      email
      displayPicture
      propertyId
      userType
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
      }
export function useGetPropertiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
        }
export function useGetPropertiesSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
        }
export type GetPropertiesQueryHookResult = ReturnType<typeof useGetPropertiesQuery>;
export type GetPropertiesLazyQueryHookResult = ReturnType<typeof useGetPropertiesLazyQuery>;
export type GetPropertiesSuspenseQueryHookResult = ReturnType<typeof useGetPropertiesSuspenseQuery>;
export type GetPropertiesQueryResult = ApolloReactCommon.QueryResult<GetPropertiesQuery, GetPropertiesQueryVariables>;
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Image,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Step,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import CheckImage from "../../assets/check.png";
import { InvitationRoles } from "../../graphql/__generated__/types";
import { useSiteDetailsStore } from "../../store/newSiteCreate";
import BrandDetails from "./BrandDetails";
import InviteUsers from "./InviteUsers";
import SiteDetails from "./SiteDetails";
import { gql } from "@apollo/client";
import { useCreatePropertyMutation } from "./__generated__/CreateSite.graphql";
import { uploadToS3 } from "../../utils/uploadToS3";
import { useGetSignedUrl } from "../../hooks/getsignedUrl";
import { useAuthStore } from "../../store/authUserStore";
import { useLogin } from "../../hooks/useLogin";
import { AllSites } from "../../pages/AllSites";
import { getDemoData } from "../../store/demoDataStore";

interface CreateSiteProps {
  isOpen: boolean;
  onClose: () => void;
}

interface RoleToInvite {
  role: InvitationRoles;
  roleName: string;
}

const rolesToInvite: RoleToInvite[] = [
  {
    role: InvitationRoles.PropertyOwner,
    roleName: "Property Owner",
  },
  {
    role: InvitationRoles.Claimer,
    roleName: "Claimer",
  },
];
const steps = [1, 2, 3];

export default function CreateSite({ isOpen, onClose }: CreateSiteProps) {
  const demoData = getDemoData();
  const btnRef = useRef(null);
  const { siteName, propertyId, resetFields } = useSiteDetailsStore();
  const allFieldsFilled: boolean = !!(demoData.siteName || siteName);
  const [siteCreated, setsiteCreated] = useState(false);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (!isOpen) {
      resetDrawer();
    }
  }, [isOpen]);

  const resetDrawer = () => {
    setActiveStep(0);
    setsiteCreated(false);
    resetFields();
  };

  const handleFinish = useCallback(
    () => setsiteCreated(!!allFieldsFilled && activeStep == 2),
    [allFieldsFilled, activeStep]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={btnRef}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      {!siteCreated ? (
        <DrawerContent maxW={500}>
          <DrawerCloseButton />
          <DrawerHeader paddingBottom={2} fontWeight="bold">
            Create new site
            <Divider mt={4} />
          </DrawerHeader>
          <DrawerBody display="flex" flexDirection="column">
            <Stepper index={activeStep} gap={0}>
              {steps.map((step) => (
                <Step
                  key={step}
                  style={{
                    gap: "0",
                  }}
                >
                  <StepIndicator
                    sx={{
                      color: "white",
                      backgroundColor: "#0072DB",
                      "[data-status=incomplete] &": {
                        borderColor: "#0072DB",
                        opacity: 0.4,
                      },
                    }}
                  >
                    <StepStatus
                      complete={<StepNumber />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <StepSeparator
                    style={{
                      margin: "0",
                    }}
                  />
                </Step>
              ))}
            </Stepper>
            <Box
              justifyContent="space-between"
              display="flex"
              flexDirection="column"
              minH="85vh"
            >
              <Container display="flex" flexDirection="column" p={0} mt={2}>
                <StepContent
                  activeStep={activeStep}
                  rolesToInvite={rolesToInvite}
                  propertyId={propertyId || ""}
                />
              </Container>

              <NavigationButtons
                activeStep={activeStep}
                allFieldsFilled={allFieldsFilled}
                handleFinish={handleFinish}
                setActiveStep={setActiveStep}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      ) : (
        <DrawerContent maxW={500}>
          <DrawerCloseButton />
          <DrawerBody>
            <SiteCreationSuccess />
          </DrawerBody>
        </DrawerContent>
      )}
    </Drawer>
  );
}
const StepContent = ({
  activeStep,
  rolesToInvite,
  propertyId,
}: {
  activeStep: number;
  rolesToInvite: RoleToInvite[];
  propertyId: string;
}) => {
  switch (activeStep) {
    case 0:
      return <SiteDetails />;
    case 1:
      return <BrandDetails />;
    case 2:
      return (
        <InviteUsers propertyId={propertyId} rolesToInvite={rolesToInvite} />
      );
    default:
      return null;
  }
};

interface NavigationButtonsProps {
  activeStep: number;
  setActiveStep: (value: React.SetStateAction<number>) => void;
  handleFinish: () => void;
  allFieldsFilled: boolean;
}

function NavigationButtons({
  activeStep,
  setActiveStep,
  handleFinish,
  allFieldsFilled,
}: NavigationButtonsProps) {
  const demoData = getDemoData();
  const [createProperty, { loading: createSiteLoading }] =
    useCreatePropertyMutation();
  const { siteName, siteLogo, siteImage, setPropertyId } =
    useSiteDetailsStore();
  const toast = useToast();
  const { setUser, user, token, logout } = useAuthStore();
  const { fetchUser } = useLogin();

  const { getSignedUrl, loading: getsignedUrlLoading } = useGetSignedUrl();
  const [uploading, setUploading] = useState<boolean>(false);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);

  const uploadFile = async (file: File | null): Promise<string | null> => {
    if (!file) return null;
    const { signedUrl, key } = await getSignedUrl(file.name, file.type);
    await uploadToS3(signedUrl, file);
    return key;
  };

  const handleCreateSite = async () => {
    try {
      setUploading(true);
      const [logoUrl, siteImageUrl] = await Promise.all([
        uploadFile(siteLogo),
        uploadFile(siteImage),
      ]);
      setUploading(false);
      const { data } = await createProperty({
        variables: {
          name: siteName || demoData.siteName || "",
          isFake: false,
          logoUrl: logoUrl,
          imageUrl: siteImageUrl,
          country: "Netherlands",
          city: "Utrecht",
        },
        refetchQueries: [
          {
            query: AllSites.graphql.queries.GetProperties,
          },
        ],
        awaitRefetchQueries: true,
      });
      setPropertyId(data?.createProperty?.id || null);
      setUploading(true);
      if (user?.userId && token)
        fetchUser(user?.userId, token)
          .then((fetchedUser) => {
            setUser({
              userId: fetchedUser.id,
              name: fetchedUser.name,
              email: fetchedUser.email,
              displayPicture: fetchedUser.displayPicture,
              userType: fetchedUser.userType,
              propertyId: fetchedUser.propertyId,
            });
          })
          .catch((err) => {
            console.error(err);
            logout();
          });
      setUploading(false);
      return true;
    } catch (err) {
      toast({
        title: String(err),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setUploading(false);
      return false;
    }
  };

  const handleOnclickNext = async () => {
    let proceed = true;

    if (activeStep === 1) {
      proceed = await handleCreateSite();
    }

    if (proceed) {
      handleNext();
      if (activeStep === 2) {
        handleFinish();
      }
    }
  };

  const isDisabled = () => {
    if (activeStep == 0) return !!!allFieldsFilled;
    if (activeStep == 1) return !(!!siteLogo && !!siteImage);
    return false;
  };

  return (
    <Box
      mt={4}
      mb={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      gap={3}
    >
      <Button
        onClick={handleOnclickNext}
        isDisabled={isDisabled()}
        colorScheme="blue"
        isLoading={createSiteLoading || getsignedUrlLoading || uploading}
        rightIcon={
          activeStep != steps.length - 1 ? <ArrowForwardIcon /> : <></>
        }
        size="sm"
        borderRadius={50}
        maxH="45px"
        maxW="50%"
        w="40%"
      >
        {activeStep < 2 ? "Next" : "Finish site creation"}
      </Button>
      {activeStep === 1 && (
        <>
          <Button
            bgColor="rgba(0, 67, 138, 0.06)"
            size="sm"
            borderRadius={50}
            maxH="45px"
            maxW="50%"
            w="40%"
            onClick={handleNext}
          >
            Skip
          </Button>
          <Text fontSize="sm">
            You can always skip this step and fill it later.
          </Text>
        </>
      )}
    </Box>
  );
}

const SiteCreationSuccess = () => (
  <Box
    h="100%"
    backgroundColor="white"
    fontSize="lg"
    fontWeight={700}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <Box backgroundColor="">
      <Image src={CheckImage} alt="Check mark" />
    </Box>
    Site created succesfully!
  </Box>
);

CreateSite.graphql = {
  mutations: {
    createSite: gql`
      mutation createProperty(
        $name: String!
        $description: String
        $logoUrl: String
        $imageUrl: String
        $isFake: Boolean!
        $country: String
        $city: String
      ) {
        createProperty(
          name: $name
          description: $description
          logoUrl: $logoUrl
          isFake: $isFake
          imageUrl: $imageUrl
          country: $country
          city: $city
        ) {
          id
          name
          description
          logoUrl
          imageUrl
          isFake
          createdBy
          createdByUser {
            id
            name
            email
            displayPicture
            propertyId
            userType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    `,
  },
};

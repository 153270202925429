/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetSignedUrlMutationVariables = Types.Exact<{
  filename: Types.Scalars['String']['input'];
  contentType: Types.Scalars['String']['input'];
  assetType?: Types.InputMaybe<Types.AllowedAsset>;
}>;


export type GetSignedUrlMutation = { __typename: 'Mutation', getSignedUrl?: { __typename: 'SignedUrlResponse', signedUrl: string, key: string } | null };


export const GetSignedUrlDocument = gql`
    mutation GetSignedUrl($filename: String!, $contentType: String!, $assetType: AllowedAsset) {
  getSignedUrl(
    fileName: $filename
    contentType: $contentType
    assetType: $assetType
  ) {
    signedUrl
    key
  }
}
    `;
export type GetSignedUrlMutationFn = ApolloReactCommon.MutationFunction<GetSignedUrlMutation, GetSignedUrlMutationVariables>;

/**
 * __useGetSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlMutation, { data, loading, error }] = useGetSignedUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      contentType: // value for 'contentType'
 *      assetType: // value for 'assetType'
 *   },
 * });
 */
export function useGetSignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GetSignedUrlMutation, GetSignedUrlMutationVariables>(GetSignedUrlDocument, options);
      }
export type GetSignedUrlMutationHookResult = ReturnType<typeof useGetSignedUrlMutation>;
export type GetSignedUrlMutationResult = ApolloReactCommon.MutationResult<GetSignedUrlMutation>;
export type GetSignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>;
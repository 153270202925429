import {
  Box,
  Container,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useRef } from "react";
import { Tv } from "../graphql/__generated__/types";

interface DeviceDetailsProps {
  device: Tv;
  isOpen: boolean;
  onClose: () => void;
}

export default function DeviceDetails({
  device,
  isOpen,
  onClose,
}: DeviceDetailsProps) {
  const btnRef = useRef(null);
  const tabs = ["Overview", "Activity", "Settings"];
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      finalFocusRef={btnRef}
      closeOnOverlayClick={false}
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader
          paddingBottom={2}
          display="flex"
          alignItems="end"
          fontWeight="none"
        >
          <Text fontWeight="600" fontSize="16px">
            Guest Room {device.roomId}
          </Text>
          {/* <Text fontSize="14px" ml={1}>
            2nd floor
          </Text> */}
        </ModalHeader>
        <ModalBody>
          <Tabs align="start" variant="unstyled">
            <TabList>
              {tabs.map((tab) => (
                <Tab width="200px" _selected={{ color: "blue.500" }} key={tab}>
                  {tab}
                </Tab>
              ))}
            </TabList>
            <TabIndicator height="2px" bg="blue.400" borderRadius="3px" />
            <TabPanels>
              {tabs.map((tab) => (
                <TabPanel key={tab}>
                  <DeviceInfo device={device} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

interface DeviceInfoProps {
  device: Tv;
}

const DeviceInfo = ({ device }: DeviceInfoProps) => {
  return (
    <Container
      width="100%"
      height="100%"
      display="flex"
      maxWidth="none"
      p={2}
      gap={6}
      flexDirection="column"
    >
      <Text fontWeight="bold">Details </Text>
      <Container
        display="flex"
        flex="1"
        maxWidth="none"
        maxHeight="none"
        gap={10}
        p={0}
      >
        <Box
          boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
          borderRadius="lg"
          p={4}
          flex="1"
        >
          <VStack
            divider={<Divider borderColor="gray.200" />}
            align="left"
            spacing={4}
          >
            <VStack align="stretch" spacing={1}>
              <Text fontWeight="bold">Device Name</Text>
              <Text>Philips S1</Text>
            </VStack>
            <VStack align="stretch" spacing={1}>
              <Text fontWeight="bold">Serial Number</Text>
              <Text>{device.serialNumber}</Text>
            </VStack>
            <VStack align="stretch" spacing={1}>
              <Text fontWeight="bold">Claimed Date</Text>
              <Text>
                {device.claimDate && format(device.claimDate, "do MMMM yyyy")}
              </Text>
            </VStack>
            <VStack align="stretch" spacing={1}>
              <Text fontWeight="bold">Last Update</Text>
              <Text>
                {device.configInstalledDate &&
                  format(device.configInstalledDate, "do MMMM yyyy")}
              </Text>
            </VStack>
          </VStack>
        </Box>
        <Box
          boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
          borderRadius="lg"
          p={4}
          flex="1"
        >
          <VStack
            divider={<Divider borderColor="gray.200" />}
            align="left"
            spacing={4}
          >
            <VStack align="stretch" spacing={1}>
              <Text fontWeight="bold">Network</Text>
              <Text>
                <b>Wifi Mac : </b> 175.231.116.47
              </Text>
              <Text>
                <b>Ethernet Mac : </b> {device.ethernetMacAddress}
              </Text>
              <Text>
                <b>Local Ip : </b>
                {device.ipAddress}
              </Text>
            </VStack>
            <VStack align="stretch" spacing={2}>
              <Text fontWeight="bold">Display</Text>
              <Text>
                <b>Model :</b> {device.model}
              </Text>
              <Text>
                <b>Firmware :</b> TPM181HE_R.205.002.023.001
              </Text>
            </VStack>
          </VStack>
        </Box>
      </Container>
      <Container p={0} maxW="none" gap={5} display="flex" flexDir="column">
        <Text fontWeight="bold">Controls</Text>
        <Box display="flex" gap={10}>
          <Text>
            <b>Power : </b>
            ON
          </Text>
          <Text>
            <b>Volume : </b>
            27
          </Text>
        </Box>
      </Container>
      <Container p={0} maxW="none">
        <Text fontWeight="bold" mb={4}>
          Status
        </Text>
        <HStack
          boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
          divider={
            <Divider
              orientation="vertical"
              borderColor="rgba(221, 221, 221, 1)"
              h="50px"
              px={10}
            />
          }
          alignItems="center"
          p={5}
        >
          <Box flex="1">
            <Text fontWeight="bold">Network Status</Text>
            <Text>Online</Text>
          </Box>
          <Box flex="1">
            <Text fontWeight="bold">Runtime</Text>
            <Text>00h 2m</Text>
          </Box>
          <Box flex="1">
            <Text fontWeight="bold">Licence</Text>
            <Text>Active</Text>
          </Box>
          <Box flex="1">
            <Text fontWeight="bold">Config file</Text>
            <Text fontSize={14}>{device.configFile}</Text>
          </Box>
        </HStack>
      </Container>
    </Container>
  );
};

import { DeviceManagerDashboard } from "../components/DeviceManagerDashboard";
import { BrandManagerDashboard } from "../components/BrandManagerDashboard";
import { PropertyOwnerDashboard } from "../components/PropertyOwnerDashboard";
import { useAuthStore } from "../store/authUserStore";
import AdminDashboard from "../components/AdminDashboard";
import UserDashboard from "../components/UserDashboard";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";

export function Dashboard() {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.userType === "receptionist") {
      navigate("/hub");
    }
  }, [user, navigate]);

  if (!user) return <></>;
  switch (user.userType) {
    case "admin":
      return <AdminDashboard />;
    case "device_manager":
      return <DeviceManagerDashboard />;
    case "property_owner":
      return <PropertyOwnerDashboard />;
    case "brand_manager":
      return <BrandManagerDashboard />;
    case "claimer":
      return <BrandManagerDashboard />;
    case "receptionist":
      return <Box />;
    default:
      return <UserDashboard />;
  }
}

/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetTvsByPropertyIdQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String']['input'];
}>;


export type GetTvsByPropertyIdQuery = { __typename: 'Query', TVsByPropertyId?: Array<{ __typename: 'TV', id: string, thingId: string, model: string, roomId?: string | null, propertyId?: string | null, claimQRCode?: string | null, claimState?: Types.ClaimState | null, cloneState?: Types.CloneState | null, cloneProgress?: number | null, isFake: boolean, createdAt: string, updatedAt: string, serialNumber?: string | null, ethernetMacAddress?: string | null, ipAddress?: string | null, claimDate?: string | null, configFile?: string | null, configInstalledDate?: string | null } | null> | null };

export type GetPropertyWithTVsQueryVariables = Types.Exact<{
  propertyId: Types.Scalars['String']['input'];
}>;


export type GetPropertyWithTVsQuery = { __typename: 'Query', property?: { __typename: 'PropertyWithTVs', id: string, name: string, description?: string | null, logoUrl?: string | null, imageUrl?: string | null, isFake: boolean, createdBy: string, createdAt: string, updatedAt: string, tvs?: Array<{ __typename: 'TV', id: string, thingId: string, model: string, roomId?: string | null, propertyId?: string | null, claimQRCode?: string | null, claimState?: Types.ClaimState | null, cloneState?: Types.CloneState | null, cloneProgress?: number | null, isFake: boolean, createdAt: string, updatedAt: string, serialNumber?: string | null, ethernetMacAddress?: string | null, ipAddress?: string | null, claimDate?: string | null, configFile?: string | null, configInstalledDate?: string | null } | null> | null, createdByUser?: { __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string } | null } | null };


export const GetTvsByPropertyIdDocument = gql`
    query GetTvsByPropertyId($propertyId: String!) {
  TVsByPropertyId(propertyId: $propertyId) {
    id
    thingId
    model
    roomId
    propertyId
    claimQRCode
    claimState
    cloneState
    cloneProgress
    isFake
    createdAt
    updatedAt
    serialNumber
    ethernetMacAddress
    ipAddress
    claimDate
    configFile
    configInstalledDate
  }
}
    `;

/**
 * __useGetTvsByPropertyIdQuery__
 *
 * To run a query within a React component, call `useGetTvsByPropertyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTvsByPropertyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTvsByPropertyIdQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetTvsByPropertyIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables> & ({ variables: GetTvsByPropertyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables>(GetTvsByPropertyIdDocument, options);
      }
export function useGetTvsByPropertyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables>(GetTvsByPropertyIdDocument, options);
        }
export function useGetTvsByPropertyIdSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables>(GetTvsByPropertyIdDocument, options);
        }
export type GetTvsByPropertyIdQueryHookResult = ReturnType<typeof useGetTvsByPropertyIdQuery>;
export type GetTvsByPropertyIdLazyQueryHookResult = ReturnType<typeof useGetTvsByPropertyIdLazyQuery>;
export type GetTvsByPropertyIdSuspenseQueryHookResult = ReturnType<typeof useGetTvsByPropertyIdSuspenseQuery>;
export type GetTvsByPropertyIdQueryResult = ApolloReactCommon.QueryResult<GetTvsByPropertyIdQuery, GetTvsByPropertyIdQueryVariables>;
export const GetPropertyWithTVsDocument = gql`
    query GetPropertyWithTVs($propertyId: String!) {
  property(id: $propertyId) {
    id
    name
    description
    logoUrl
    imageUrl
    tvs {
      id
      thingId
      model
      roomId
      propertyId
      claimQRCode
      claimState
      cloneState
      cloneProgress
      isFake
      createdAt
      updatedAt
      serialNumber
      ethernetMacAddress
      ipAddress
      claimDate
      configFile
      configInstalledDate
    }
    isFake
    createdBy
    createdByUser {
      id
      name
      email
      displayPicture
      propertyId
      userType
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPropertyWithTVsQuery__
 *
 * To run a query within a React component, call `useGetPropertyWithTVsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyWithTVsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyWithTVsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropertyWithTVsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables> & ({ variables: GetPropertyWithTVsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables>(GetPropertyWithTVsDocument, options);
      }
export function useGetPropertyWithTVsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables>(GetPropertyWithTVsDocument, options);
        }
export function useGetPropertyWithTVsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables>(GetPropertyWithTVsDocument, options);
        }
export type GetPropertyWithTVsQueryHookResult = ReturnType<typeof useGetPropertyWithTVsQuery>;
export type GetPropertyWithTVsLazyQueryHookResult = ReturnType<typeof useGetPropertyWithTVsLazyQuery>;
export type GetPropertyWithTVsSuspenseQueryHookResult = ReturnType<typeof useGetPropertyWithTVsSuspenseQuery>;
export type GetPropertyWithTVsQueryResult = ApolloReactCommon.QueryResult<GetPropertyWithTVsQuery, GetPropertyWithTVsQueryVariables>;
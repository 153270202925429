import { useEffect, useState } from "react";
import { useAuthStore } from "../store/authUserStore";

const useScreenCheck = () => {
  const { user } = useAuthStore();
  const [isAllowed, setIsAllowed] = useState<boolean>(true);

  const checkScreen = () => {
    const { innerWidth, innerHeight } = window;
    const isLandscape = innerWidth > innerHeight;
    const isResolutionHighEnough = innerWidth >= 1020; //&& innerHeight >= 720;

    setIsAllowed(isLandscape && isResolutionHighEnough);
  };

  useEffect(() => {
    checkScreen();
    window.addEventListener("resize", checkScreen);
    return () => {
      window.removeEventListener("resize", checkScreen);
    };
  }, []);
  if (user?.userType === "admin") return true;
  return isAllowed;
};

export default useScreenCheck;

import { gql } from "@apollo/client";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { InvitationRoles } from "../../graphql/__generated__/types";
import { useInviteUsersMutation } from "./__generated__/InviteUsers.graphql";
import { getDemoData, storeDemoData } from "../../store/demoDataStore";
import { usersTobeCreated } from "../../demoData/demoUsersData";

interface RoleToInvite {
  role: InvitationRoles;
  roleName: string;
}

interface InviteUsersProps {
  propertyId: string;
  rolesToInvite: RoleToInvite[];
}

export default function InviteUsers({
  propertyId,
  rolesToInvite,
}: InviteUsersProps) {
  return (
    <VStack align="left" spacing={3}>
      <Text color="blue.500" fontWeight="bold">
        Invite Users
      </Text>
      <Text color="grey.500" fontSize="14px">
        You can always skip this step and add users later
      </Text>
      <Accordion allowMultiple>
        {rolesToInvite.map((item) => (
          <InviteUserAccordionItem
            roleToInvite={item}
            propertyId={propertyId}
            key={item.role}
          />
        ))}
      </Accordion>
    </VStack>
  );
}

interface InviteUserAccordionItemProps {
  roleToInvite: RoleToInvite;
  propertyId: string;
}

function InviteUserAccordionItem({
  roleToInvite,
  propertyId,
}: InviteUserAccordionItemProps) {
  const demoData = getDemoData();
  const demoUserEmail = usersTobeCreated
    .find((user) => user.userType == roleToInvite.role.toString())
    ?.email.toLowerCase();

  const [recipientEmail, setRecipientEmail] = useState(
    demoData[`${roleToInvite.roleName}email`] || demoUserEmail || ""
  );
  const [invitationSent, setInvitationSent] = useState(false);
  const [createInvitation, { loading }] = useInviteUsersMutation();

  const handleRecipientEmailChange = (email: string) => {
    storeDemoData({ [`${roleToInvite.roleName}email`]: email });
    setRecipientEmail(email);
  };

  const handleInvitation = async () => {
    if (recipientEmail && !loading) {
      try {
        await createInvitation({
          variables: {
            propertyId: propertyId,
            invitationRole: roleToInvite.role,
            recipientEmail: recipientEmail,
          },
        });
        setInvitationSent(true);
      } catch {}
    }
  };

  return (
    <AccordionItem border="none" mb={3} _expanded={{ marginBottom: "10px" }}>
      <AccordionButton
        p={3}
        px={5}
        bgColor="#F5F5F5"
        borderRadius={4}
        display="flex"
        border="1px solid #D9D9D9"
        justifyContent="space-between"
      >
        <Text fontWeight="600">{roleToInvite.roleName}</Text>
        <AccordionIcon />
      </AccordionButton>

      {invitationSent ? (
        <AccordionPanel justifyContent="center" px={0} py={1}>
          <Box
            bgColor="#41B546"
            borderRadius={5}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <CheckIcon color="white" w={3} h={3} />
            <Text color="white" ml={2} fontSize={14}>
              Sent
            </Text>
          </Box>
        </AccordionPanel>
      ) : (
        <AccordionPanel display="flex" gap={2} px={0} py={1}>
          <Input
            placeholder="Enter email"
            flex="9"
            type="email"
            value={recipientEmail}
            _focus={{
              border: "1px solid #D9D9D9",
              outline: "none",
              boxShadow: "none",
            }}
            onChange={(e) => handleRecipientEmailChange(e.target.value)}
          ></Input>
          <Button
            colorScheme="blue"
            isDisabled={!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(recipientEmail)}
            px={10}
            flex="1"
            onClick={handleInvitation}
            isLoading={loading}
          >
            Invite
          </Button>
        </AccordionPanel>
      )}
    </AccordionItem>
  );
}

InviteUsers.graphql = {
  mutations: {
    InviteUsers: gql`
      mutation InviteUsers(
        $recipientEmail: String!
        $invitationRole: InvitationRoles!
        $propertyId: String!
      ) {
        createInvitation(
          recipientEmail: $recipientEmail
          invitationRole: $invitationRole
          propertyId: $propertyId
        ) {
          id
          senderEmail
          recipientEmail
          invitationRole
          propertyId
          ttl
          isAccepted
          createdAt
          updatedAt
        }
      }
    `,
  },
};

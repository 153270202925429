import { Box, Heading, Image, VStack } from "@chakra-ui/react";
import DevicesEmptyImage from "../assets/devices_empty.png";
import React from "react";

const UserDashboard = () => {
  return (
    <Box
      height={"100%"}
      width={"100%"}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box marginTop={"-150px"}>
        <VStack width="100%" height={"100%"}>
          <Image src={DevicesEmptyImage} height="350px" />
          <Heading typeof="h2" size={"lg"}>
            Uh ho! You are not assigned to any property yet. Please wait for an
            Invite.
          </Heading>
          <p style={{ marginTop: "20px", fontSize: "22px" }}>
            Contact your admin to receive an Invite to the property.
          </p>
        </VStack>
      </Box>
    </Box>
  );
};

export default UserDashboard;

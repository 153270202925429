import { UserType } from "../graphql/__generated__/types";

export const usersTobeCreated = [
  {
    name: "Olvier Dubois",
    email: "Olivier.Dubois@wade.com",
    userType: UserType.BrandManager,
    password: "password",
    confirmPassword: "password",
  },
  {
    name: "Amelia Bianchi",
    email: "Amelia.Bianchi@so1.com",
    userType: UserType.PropertyOwner,
    password: "password",
    confirmPassword: "password",
  },
  {
    name: "Sebastian Muller",
    email: "Sebastian.Muller@dmo1.org",
    userType: UserType.DeviceManager,
    password: "password",
    confirmPassword: "password",
  },
  {
    name: "Isabella Garcia",
    email: "Isabella.Garcia@clo1.com",
    userType: UserType.Claimer,
    password: "password",
    confirmPassword: "password",
  },
  {
    name: "Klara Nowak",
    email: "Klara.Nowak@mail.com",
    userType: UserType.Receptionist,
    password: "password",
    confirmPassword: "password",
  },
  {
    name: "User",
    email: "user@email.com",
    userType: UserType.User,
    password: "password",
    confirmPassword: "password",
  },
];

import { CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { BellIcon } from "../../assets/icons/BellIcon";
import { Alert } from "../../graphql/__generated__/types";

interface AlertItemProps {
  alert: Alert;
  onAccept?: (invitationId: string, alertId: string) => Promise<void>;
}

export default function AlertItem({ alert, onAccept }: AlertItemProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      overflow="hidden"
      w="100%"
    >
      <Box display="flex" gap={2} my={1} alignItems="center">
        {alert.extras?.alertType == "invitation" ? (
          <BellIcon color="#087D2F" mr={1} />
        ) : (
          <Box
            bg="#087D2F"
            borderRadius="50%"
            w={5}
            h={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={1}
          >
            <CheckIcon color="white" w={4} h={4} />
          </Box>
        )}
        <Text color="#087D2F" fontWeight={600} w={150}>
          {alert.title}
        </Text>
        <Text w={alert.extras?.alertType == "invitation" ? 380 : 600}>
          {alert.description}
        </Text>
      </Box>
      <Box display="flex" gap={3}>
        {alert.extras?.alertType == "invitation" && (
          <>
            <Button fontWeight={100} colorScheme="red" borderRadius={50}>
              Decline
            </Button>
            <Button
              borderRadius={50}
              bg="#0A993A"
              color="white"
              fontWeight={100}
              onClick={async () => {
                if (alert.extras?.invitationId && onAccept) {
                  await onAccept(alert.extras?.invitationId, alert.id);
                }
              }}
            >
              Accept
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

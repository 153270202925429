import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/authUserStore";
import { useLogin } from "../hooks/useLogin";

interface Props {
  element: ReactNode;
}

export function Protected({ element }: Props) {
  const { isAuthenticated, user, setUser, logout, setIsAuthenticated, token } =
    useAuthStore();
  const navigate = useNavigate();

  const { fetchUser } = useLogin();
  useEffect(() => {
    if (user?.userId && token)
      fetchUser(user?.userId, token || "")
        .then((fetchedUser) => {
          setUser({
            userId: fetchedUser.id,
            name: fetchedUser.name,
            email: fetchedUser.email,
            displayPicture: fetchedUser.displayPicture,
            userType: fetchedUser.userType,
            propertyId: fetchedUser.propertyId,
          });
          setIsAuthenticated(true);
        })
        .catch((err) => {
          console.error(err);
          logout();
        });
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  return <>{element}</>;
}

export function protect(element: ReactNode) {
  return <Protected element={element} />;
}

export function RedirectIfLoggedIn({ element }: Props) {
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return <>{element}</>;
}

export function redirectIfLoggedIn(element: ReactNode) {
  return <RedirectIfLoggedIn element={element} />;
}

import { Box, Image, Input, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import ImagePickerHolder from "../../assets/imagepicker_holder.png";
import PromessaImage from "../../assets/promessa_image.jpeg";
import PromessaLogo from "../../assets/promessa_logo.png";
import { useSiteDetailsStore } from "../../store/newSiteCreate";

export default function BrandDetails() {
  const { siteLogo, siteImage, setSiteLogo, setSiteImage } =
    useSiteDetailsStore();

  useEffect(() => {
    const fetchFile = async (
      fileUrl: string,
      fileName: string,
      setObj: (img: File | null) => void
    ) => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        setObj(file);
      } catch (error) {
        console.error("Error fetching the file:", error);
      }
    };
    fetchFile(PromessaLogo, "promessa_logo.png", setSiteLogo);
    fetchFile(PromessaImage, "promessa_image.jpeg", setSiteImage);
  }, []);

  return (
    <VStack align="left" spacing={3}>
      <Text color="#0072DB" fontWeight="bold">
        Brand Details
      </Text>
      <AddImages
        title="Add logo file"
        multiple={false}
        image={siteLogo}
        setImage={setSiteLogo}
      />
      <AddImages
        title="Add site images"
        multiple={false}
        image={siteImage}
        setImage={setSiteImage}
      />
    </VStack>
  );
}

interface AddImagesProps {
  title: string;
  multiple: boolean;
  image: File | null;
  setImage: (siteImages: File | null) => void;
}

function AddImages({ title, multiple, image, setImage }: AddImagesProps) {
  const handleSingleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setImage(file);
    } else {
      setImage(null);
    }
  };

  // const handleMultiFileSelection = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const files = event.target.files;
  //   if (files?.length) {
  //     if (files.length > 10) {
  //       alert("You can only upload up to 10 images.");
  //       return;
  //     }
  //     setSiteImages(Array.from(files));
  //   } else {
  //     setSiteImages([]);
  //   }
  // };

  return (
    <VStack align="left">
      <Text ml={3} fontSize={14}>
        {title}
      </Text>
      <Box w="100%">
        {multiple ? (
          <></>
        ) : (
          <label htmlFor={`single-image-picker-${title.replace(/ /g, "-")}`}>
            <Image
              src={image ? URL.createObjectURL(image) : ImagePickerHolder}
              cursor="pointer"
              h={215}
              w="100%"
              borderRadius={12}
              fit="cover"
              fill="contain"
            />
          </label>
        )}
      </Box>
      <Input
        type="file"
        id={`single-image-picker-${title.replace(/ /g, "-")}`}
        multiple={multiple}
        onChange={handleSingleFileSelection}
        style={{ display: "none" }}
        accept="image/*"
      />
    </VStack>
  );
}

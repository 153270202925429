import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function PagePadding({
  children,
  height,
}: {
  children: ReactNode;
  height?: string;
}) {
  return (
    <Box p={10} h={height}>
      {children}
    </Box>
  );
}

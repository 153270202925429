/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAlertsQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type GetAlertsQuery = { __typename: 'Query', alerts?: Array<{ __typename: 'Alert', id: string, targetType: Types.AlertTarget, targetId: string, title: string, description: string, landingUrl?: string | null, status: Types.AlertStatus, createdAt: string, updatedAt: string, extras?: { __typename: 'AlertExtras', alertType?: Types.AlertTypeEnum | null, propertyName?: string | null, propertyLogo?: string | null, invitationId?: string | null } | null } | null> | null };

export type AcceptInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type AcceptInvitationMutation = { __typename: 'Mutation', acceptInvitation?: boolean | null };

export type MarkAlertAsReadMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type MarkAlertAsReadMutation = { __typename: 'Mutation', markAlertAsRead?: { __typename: 'Alert', id: string } | null };


export const GetAlertsDocument = gql`
    query GetAlerts($userId: String!) {
  alerts(userId: $userId) {
    id
    targetType
    targetId
    title
    description
    landingUrl
    status
    extras {
      alertType
      propertyName
      propertyLogo
      invitationId
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAlertsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables> & ({ variables: GetAlertsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAlertsQuery, GetAlertsQueryVariables>(GetAlertsDocument, options);
      }
export function useGetAlertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAlertsQuery, GetAlertsQueryVariables>(GetAlertsDocument, options);
        }
export function useGetAlertsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAlertsQuery, GetAlertsQueryVariables>(GetAlertsDocument, options);
        }
export type GetAlertsQueryHookResult = ReturnType<typeof useGetAlertsQuery>;
export type GetAlertsLazyQueryHookResult = ReturnType<typeof useGetAlertsLazyQuery>;
export type GetAlertsSuspenseQueryHookResult = ReturnType<typeof useGetAlertsSuspenseQuery>;
export type GetAlertsQueryResult = ApolloReactCommon.QueryResult<GetAlertsQuery, GetAlertsQueryVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($id: String!) {
  acceptInvitation(id: $id)
}
    `;
export type AcceptInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = ApolloReactCommon.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const MarkAlertAsReadDocument = gql`
    mutation MarkAlertAsRead($id: String!) {
  markAlertAsRead(id: $id) {
    id
  }
}
    `;
export type MarkAlertAsReadMutationFn = ApolloReactCommon.MutationFunction<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>;

/**
 * __useMarkAlertAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAlertAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAlertAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAlertAsReadMutation, { data, loading, error }] = useMarkAlertAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAlertAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>(MarkAlertAsReadDocument, options);
      }
export type MarkAlertAsReadMutationHookResult = ReturnType<typeof useMarkAlertAsReadMutation>;
export type MarkAlertAsReadMutationResult = ApolloReactCommon.MutationResult<MarkAlertAsReadMutation>;
export type MarkAlertAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>;
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { DashboardTile } from "./DashboardTile";
import ScreenTimeImage from "../assets/screen_time_image.png";
import ChannelTimeImage from "../assets/channel_time_image.png";
import DashboardEuropeDropdownImage from "../assets/dashboard_europe_dropdown.png";
import { useNavigate } from "react-router-dom";
import { PODashboardBackground } from "./DashboardBackground";
import DisplayRuntimeGraphImage from "../assets/display_runtime_graph_image.png";

interface DisplayRuntimeGraphProps {
  isOpen: boolean;
  onClose: () => void;
}

const DisplayRuntimeGraph = ({ isOpen, onClose }: DisplayRuntimeGraphProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent minWidth={"800px"} marginLeft={"480px"}>
        <ModalCloseButton />
        <ModalBody p={9}>
          <Image src={DisplayRuntimeGraphImage} alt="Display Runtime Graph" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const PropertyOwnerDashboard = () => {
  const navigate = useNavigate();
  const displayRuntimeModal = useDisclosure();
  const fontSize = 56;
  const DashboardTileData = [
    {
      Main: (
        <Box fontSize={fontSize} display="flex" justifyContent={"center"}>
          7
        </Box>
      ),
      Sub: (
        <Box display="flex" justifyContent={"center"}>
          Total Properties
        </Box>
      ),
      onClick: () => navigate("/sites"),
    },
    {
      Main: (
        <Box fontSize={fontSize} display="flex" justifyContent={"center"}>
          655
        </Box>
      ),
      Sub: (
        <Box display="flex" justifyContent={"center"}>
          Total Displays
        </Box>
      ),
    },
    {
      Main: (
        <Box>
          <HStack alignItems={"baseline"} justifyContent={"center"}>
            <Box fontSize={fontSize}>1.7</Box>
            <Box fontWeight="bold" marginLeft={"-10px"}>
              h/day
            </Box>
          </HStack>
        </Box>
      ),
      Sub: (
        <Box display="flex" justifyContent={"center"}>
          Avg. Display runtime
        </Box>
      ),
      onClick: displayRuntimeModal.onOpen,
    },
  ];
  return (
    <>
      <Box>
        <PODashboardBackground />
        <Box
          zIndex={3}
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          p={10}
          pb={0}
        >
          <HStack>
            <img
              src={DashboardEuropeDropdownImage}
              alt="Location"
              width={"10%"}
            />
          </HStack>

          <Grid
            templateColumns="repeat(7, 1fr)"
            templateRows="repeat(4, 1fr)"
            gap={5}
            width={"100%"}
            height={"95%"}
          >
            {DashboardTileData.map((tileData, index) => (
              <GridItem
                rowStart={index + 1}
                rowEnd={index + 2}
                rowSpan={1}
                colStart={0}
                colEnd={2}
                marginTop={5}
                key={index}
                onClick={tileData.onClick}
                cursor={"pointer"}
              >
                <DashboardTile {...tileData} justifyContent="center" />
              </GridItem>
            ))}

            <GridItem colStart={6} colEnd={8} rowSpan={2}>
              <img src={ScreenTimeImage} alt="Screen Time" />
            </GridItem>
            <GridItem colStart={6} colEnd={8} rowStart={3} rowEnd={5}>
              <img src={ChannelTimeImage} alt="Channel Time" />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      {displayRuntimeModal.isOpen && (
        <DisplayRuntimeGraph
          isOpen={displayRuntimeModal.isOpen}
          onClose={displayRuntimeModal.onClose}
        />
      )}
    </>
  );
};

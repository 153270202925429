import { gql } from "@apollo/client";
import { AddIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Image,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import CheckInIcon from "../assets/icons/CheckInIcon";
import CheckoutIcon from "../assets/icons/CheckoutIcon";
import { FilterIcon } from "../assets/icons/FilterIcon";
import { SettingsIcon } from "../assets/icons/SettingsIcon";
import { StatsIcon } from "../assets/icons/StatsIcon";
import PagePadding from "../components/PagePadding";
import CheckIn from "../components/Room/CheckIn";
import CheckOut, { languageLabels } from "../components/Room/Checkout";
import { Room } from "../graphql/__generated__/types";
import { useAuthStore } from "../store/authUserStore";
import { useGetPropertyWithTVsQuery } from "./__generated__/DevicesList.graphql";
import { useGetRoomsQuery } from "./__generated__/Hub.graphql";
import MessageIcon from "../assets/icons/MessageIcon";
import { POLL_INTERVAL } from "../config";
import { useConditionalPolling } from "../hooks/useConditionalPolling";
import DevicesEmptyImage from "../assets/devices_empty.png";

export default function Hub() {
  const { user } = useAuthStore();
  const createCheckinModal = useDisclosure();
  const propertyId = user?.propertyId || "";

  const { data: property } = useGetPropertyWithTVsQuery({
    variables: { propertyId },
    skip: !propertyId,
  });

  const {
    data: roomsList,
    refetch: refetchRooms,
    startPolling,
    stopPolling,
  } = useGetRoomsQuery({
    variables: { propertyId },
    skip: !propertyId,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  useConditionalPolling({
    startPolling,
    stopPolling,
    pollInterval: POLL_INTERVAL,
  });

  const checkedOutRooms = roomsList?.rooms?.filter(
    (room) => room?.checkInState != true
  );
  return (
    <PagePadding>
      {user?.propertyId ? (
        <VStack
          spacing={3}
          bgColor="white"
          p={4}
          borderRadius={12}
          boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
        >
          <TableTopBar
            propertyId={propertyId}
            propertyName={property?.property?.name || ""}
            createCheckinModal={createCheckinModal}
            roomIds={checkedOutRooms
              ?.filter((room) => room !== null)
              .map((room) => room!.roomId)}
          />
          <TableContainer width="100%">
            <Table>
              <Thead
                sx={{
                  th: {
                    textTransform: "none",
                    color: "black",
                    fontSize: "15px",
                  },
                }}
              >
                <Tr>
                  <Th>
                    Room
                    <Icon as={ArrowUpIcon} w={4} h={4} ml={2} />
                  </Th>
                  <Th>Guest</Th>
                  <Th>Check In</Th>
                  <Th>Check Out</Th>
                  <Th>RC Battery</Th>
                  <Th>Display Status</Th>
                  <Th>Checkin-Checkout</Th>
                </Tr>
              </Thead>
              <Tbody>
                {roomsList?.rooms?.map(
                  (room) =>
                    room && (
                      <RoomsRow
                        room={room}
                        key={room.id}
                        refetchRooms={refetchRooms}
                      />
                    )
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      ) : (
        <ReceptionistEmptyView />
      )}
    </PagePadding>
  );
}

interface RoomsRowProps {
  room: Room;
  refetchRooms: () => void;
}
function RoomsRow({ room, refetchRooms }: RoomsRowProps) {
  const createCheckOutModal = useDisclosure();
  const createCheckInModal = useDisclosure();

  return (
    <Tr
      style={{ cursor: "pointer" }}
      onClick={
        room.isFake
          ? () => {}
          : room.checkInState
          ? createCheckOutModal.onOpen
          : createCheckInModal.onOpen
      }
    >
      <Td w="200px">
        <Text fontWeight="600" fontSize="16px">
          Guest Room {room.roomId}
        </Text>
      </Td>
      <Td>
        <VStack align="start">
          <Text>{room.guestName || "Empty"}</Text>
          {/* <Text fontSize="sm">{room.guestEmail || "Empty"}</Text> */}
        </VStack>
      </Td>
      {/* <Td>
        <VStack align="start">
          <Text>
            {languageLabels[room.guestLanguage as LanguageCode] || "Empty"}
          </Text>
        </VStack>
      </Td> */}
      <Td>
        {room.checkInDate ? format(room.checkInDate, "dd MMM yyyy") : "NA"}
      </Td>
      <Td>
        {room.checkOutDate ? format(room.checkOutDate, "dd MMM yyyy") : "NA"}
      </Td>
      <Td>OK</Td>
      <Td>
        <Box
          backgroundColor="#41B546"
          color="white"
          width="150px"
          p={2}
          borderRadius={10}
          textAlign="center"
        >
          Stable
        </Box>
      </Td>
      <Td>
        {room.checkInState == true ? (
          <>
            <CheckoutIcon />
            <CheckOut
              isOpen={createCheckOutModal.isOpen}
              onClose={() => {
                createCheckOutModal.onClose();
                refetchRooms();
              }}
              room={room}
            />
          </>
        ) : (
          <>
            <CheckInIcon />
            <CheckIn
              isOpen={createCheckInModal.isOpen}
              onClose={() => {
                createCheckInModal.onClose();
                refetchRooms();
              }}
              roomIds={room.roomId}
            />
          </>
        )}
      </Td>
    </Tr>
  );
}

interface TableTopBarProps {
  propertyName: string;
  createCheckinModal: UseDisclosureReturn;
  propertyId: string;
  roomIds?: string[];
}

function TableTopBar({
  propertyName,
  createCheckinModal,
  propertyId,
  roomIds,
}: TableTopBarProps) {
  return (
    <HStack justifyContent="space-between" alignItems="center" width="100%">
      <Text fontSize={18} fontWeight={600}>
        {propertyName}
      </Text>
      <HStack spacing={5}>
        <Button rightIcon={<FilterIcon />} variant="ghost">
          Filter
        </Button>
        <Button rightIcon={<SettingsIcon />} variant="ghost">
          Customize
        </Button>
        <Button rightIcon={<StatsIcon />} variant="ghost">
          Overview Stats
        </Button>
      </HStack>
      <Box>
        <Button
          leftIcon={<MessageIcon />}
          bg="#00438A0F"
          borderRadius={50}
          px={10}
          py={0}
          size="sm"
          mr={3}
        >
          Send Message
        </Button>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          borderRadius={50}
          px={10}
          py={0}
          size="sm"
          onClick={createCheckinModal.onOpen}
        >
          Create Check In
        </Button>
      </Box>
      {propertyId.length && roomIds && (
        <CheckIn
          isOpen={createCheckinModal.isOpen}
          onClose={createCheckinModal.onClose}
          roomIds={roomIds}
        />
      )}
    </HStack>
  );
}
export const ReceptionistEmptyView = () => {
  return (
    <Box
      height={"100%"}
      width={"100%"}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box>
        <VStack width="100%" height={"100%"}>
          <Image src={DevicesEmptyImage} height="350px" />
          <Text fontWeight="600" fontSize="px">
            Uh ho! There&apos;s nothing to do just yet.
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

Hub.graphql = {
  queries: {
    GetRooms: gql`
      query GetRooms($propertyId: String!) {
        rooms(propertyId: $propertyId) {
          id
          propertyId
          roomId
          checkInState
          guestName
          isFake
          createdAt
          updatedAt
          checkInDate
          checkOutDate
          guestLanguage
        }
      }
    `,
  },
};

export const POLL_INTERVAL = parseInt(process.env.POLL_INTERVAL || "3000");

declare global {
  interface String {
    toTitleCase(): string;
  }
}

String.prototype.toTitleCase = function () {
  return this.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

import { gql } from "@apollo/client";
import {
  Box,
  Divider,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  ToastId,
  useDisclosure,
  useOutsideClick,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BellIcon } from "../../assets/icons/BellIcon";
import { POLL_INTERVAL } from "../../config";
import { Alert } from "../../graphql/__generated__/types";
import { useConditionalPolling } from "../../hooks/useConditionalPolling";
import { useLogin } from "../../hooks/useLogin";
import { useAuthStore } from "../../store/authUserStore";
import {
  useAcceptInvitationMutation,
  useGetAlertsQuery,
  useMarkAlertAsReadMutation,
} from "./__generated__/Alerts.graphql";
import AlertItem from "./AlertItem";
import AlertToast from "./AlertToast";

export default function Alerts() {
  const contentRef = useRef<HTMLElement>(null);
  const { user, isAuthenticated, setUser, token, logout } = useAuthStore();
  const userId = user?.userId || "";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { fetchUser } = useLogin();
  const [acceptInvitation] = useAcceptInvitationMutation();
  const [markAlertAsRead] = useMarkAlertAsReadMutation();
  const navigate = useNavigate();
  const toast = useToast();
  const isInitialMount = useRef(true);
  const [previousAlerts, setPreviousAlerts] = useState<Alert[]>([]);
  const activeToastsRef = useRef<
    { toastId: string | ToastId; alertId: string }[]
  >([]);

  useOutsideClick({
    ref: contentRef,
    handler: onClose,
  });

  const {
    data: alertsData,
    startPolling,
    stopPolling,
  } = useGetAlertsQuery({
    variables: {
      userId,
    },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "network-only",
    skip: !isAuthenticated,
  });
  useConditionalPolling({
    startPolling,
    stopPolling,
    pollInterval: POLL_INTERVAL,
  });

  useEffect(() => {
    if (!alertsData?.alerts) return;

    const currentAlerts = alertsData?.alerts
      .filter((alert) => alert !== null)
      .sort((a, b) => {
        const dateA = a?.createdAt ? new Date(a.createdAt).getTime() : 0;
        const dateB = b?.createdAt ? new Date(b.createdAt).getTime() : 0;
        return dateB - dateA;
      })
      .slice(0, 3);

    if (isInitialMount.current) {
      isInitialMount.current = false;
      setPreviousAlerts(currentAlerts);
    }

    const previousAlertIds = new Set(previousAlerts.map((alert) => alert.id));
    const newAlerts = currentAlerts.filter(
      (alert) => !previousAlertIds.has(alert.id)
    );

    if (activeToastsRef.current.length >= 3) {
      const oldestToast = activeToastsRef.current.pop();
      if (oldestToast) {
        toast.close(oldestToast.toastId);
      }
    }

    newAlerts.reverse().forEach((alert) => {
      const toastId = toast({
        render: ({ onClose }) => (
          <AlertToast alert={alert} onClose={onClose} onAccept={handleAccept} />
        ),
        position: "top",
        duration: 60000,
        containerStyle: {
          maxWidth: "none",
        },
        onCloseComplete: () => {
          activeToastsRef.current = activeToastsRef.current.filter(
            (t) => t.toastId !== toastId
          );
        },
      });

      activeToastsRef.current.unshift({
        toastId,
        alertId: alert.id,
      });
    });

    setPreviousAlerts(currentAlerts);
  }, [alertsData?.alerts]);

  const handleAccept = useCallback(
    async (invitationId: string, alertId: string) => {
      try {
        const { data } = await acceptInvitation({
          variables: {
            id: invitationId,
          },
        });
        await markAlertAsRead({ variables: { id: alertId } });
        if (data?.acceptInvitation) {
          toast({
            title: "Invitation Accepted!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
        if (user?.userId && token) {
          try {
            const fetchedUser = await fetchUser(user?.userId, token);
            setUser({
              userId: fetchedUser.id,
              name: fetchedUser.name,
              email: fetchedUser.email,
              displayPicture: fetchedUser.displayPicture,
              userType: fetchedUser.userType,
              propertyId: fetchedUser.propertyId,
            });
            switch (fetchedUser.userType) {
              case "receptionist":
                navigate("/hub");
                break;
              case "device_manager":
              case "property_owner":
              case "brand_manager":
                navigate("/sites");
                break;
              default:
                break;
            }
          } catch (err) {
            console.error(err);
            logout();
          }
        }
        onClose();
      } catch {}
    },
    [acceptInvitation, logout, toast, setUser, fetchUser, token, user]
  );

  return (
    <Box position="relative" display="inline-block">
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
        closeOnEsc={true}
        closeOnBlur={true}
        initialFocusRef={contentRef}
      >
        <PopoverTrigger>
          <Box cursor="pointer">
            {alertsData?.alerts && alertsData.alerts?.length > 0 && (
              <Box
                position="absolute"
                top="2"
                right="2"
                width="12px"
                height="12px"
                bg="red"
                borderRadius="full"
                border="2px solid"
                borderColor="white"
                zIndex={1}
              />
            )}
            <IconButton
              icon={<BellIcon />}
              aria-label="Notification"
              variant="ghost"
              onClick={() => {
                if (isOpen) onClose();
                else onOpen();
                toast.closeAll();
              }}
            />
          </Box>
        </PopoverTrigger>
        {alertsData?.alerts && alertsData.alerts?.length > 0 && (
          <PopoverContent width={800} mr={2} borderRadius={10}>
            <PopoverArrow ml={1} />
            <PopoverCloseButton />
            <PopoverHeader>
              <Text color="#0B5ED7">
                All Alerts ({alertsData?.alerts.length})
              </Text>
            </PopoverHeader>
            <PopoverBody p={5}>
              <VStack spacing={2} divider={<Divider />}>
                {alertsData?.alerts
                  .slice()
                  .sort((a, b) => {
                    const dateA = a?.createdAt
                      ? new Date(a.createdAt).getTime()
                      : 0;
                    const dateB = b?.createdAt
                      ? new Date(b.createdAt).getTime()
                      : 0;
                    return dateB - dateA;
                  })
                  .slice(0, 6)
                  .map((alert) => (
                    <AlertItem
                      alert={alert as Alert}
                      key={alert?.id}
                      onAccept={handleAccept}
                    />
                  ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
}

Alerts.graqpql = {
  queries: {
    GetAlerts: gql`
      query GetAlerts($userId: String!) {
        alerts(userId: $userId) {
          id
          targetType
          targetId
          title
          description
          landingUrl
          status
          extras {
            alertType
            propertyName
            propertyLogo
            invitationId
          }
          createdAt
          updatedAt
        }
      }
    `,
  },
  mutations: {
    AcceptInvitation: gql`
      mutation AcceptInvitation($id: String!) {
        acceptInvitation(id: $id)
      }
    `,
    MarkAsRead: gql`
      mutation MarkAlertAsRead($id: String!) {
        markAlertAsRead(id: $id) {
          id
        }
      }
    `,
  },
};

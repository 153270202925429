import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "Guest Icon",
  viewBox: "0 0 20 21",
  defaultProps: {
    fill: "none",
    height: "21",
    width: "20",
  },
  path: (
    <path
      d="M15.928 18.0677H18.066C18.2541 18.0706 18.44 18.028 18.608 17.9434C18.776 17.8589 18.9211 17.7349 19.0307 17.5821C19.1404 17.4293 19.2114 17.2522 19.2378 17.066C19.2641 16.8798 19.245 16.6899 19.182 16.5127C18.6368 15.3458 17.7738 14.3563 16.6918 13.6576C15.6098 12.9589 14.3528 12.5794 13.065 12.5627M13.065 9.80369C13.5462 9.80382 14.0228 9.70915 14.4675 9.52507C14.9121 9.341 15.3161 9.07113 15.6565 8.73088C15.9968 8.39063 16.2668 7.98667 16.451 7.54207C16.6352 7.09747 16.73 6.62094 16.73 6.13969C16.7313 5.6576 16.6375 5.17999 16.4539 4.73424C16.2703 4.28848 16.0005 3.88334 15.66 3.54203C15.3196 3.20072 14.9151 2.92996 14.4698 2.74526C14.0245 2.56056 13.5471 2.46556 13.065 2.46569M7.34 9.91869C8.44174 9.91604 9.49744 9.47645 10.2755 8.69637C11.0535 7.91629 11.4903 6.85943 11.49 5.75769C11.49 4.65678 11.0527 3.60095 10.2742 2.82249C9.49574 2.04403 8.43991 1.60669 7.339 1.60669C6.23809 1.60669 5.18226 2.04403 4.4038 2.82249C3.62534 3.60095 3.188 4.65678 3.188 5.75769C3.188 6.85943 3.62505 7.91618 4.40325 8.69608C5.18146 9.47597 6.23726 9.91531 7.339 9.91769M12.025 19.2607C12.3772 19.2602 12.7225 19.1622 13.0224 18.9776C13.3224 18.793 13.5654 18.529 13.7246 18.2147C13.8837 17.9005 13.9528 17.5483 13.9241 17.1973C13.8954 16.8462 13.7701 16.5099 13.562 16.2257C12.8331 15.2582 11.8948 14.4681 10.8173 13.9146C9.73977 13.3611 8.551 13.0586 7.34 13.0297C6.12891 13.0587 4.94009 13.3614 3.86258 13.9151C2.78506 14.4687 1.84678 15.259 1.118 16.2267C0.910657 16.5109 0.785901 16.847 0.757508 17.1976C0.729116 17.5483 0.798192 17.9001 0.957109 18.214C1.11603 18.5279 1.3586 18.7917 1.65805 18.9764C1.9575 19.1612 2.30217 19.2595 2.654 19.2607H12.025Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

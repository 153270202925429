/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GuestCheckInMutationVariables = Types.Exact<{
  roomId: Types.Scalars['String']['input'];
  guestName: Types.Scalars['String']['input'];
  checkInDate: Types.Scalars['String']['input'];
  checkOutDate: Types.Scalars['String']['input'];
  guestLanguage: Types.LanguageCode;
}>;


export type GuestCheckInMutation = { __typename: 'Mutation', guestCheckIn?: { __typename: 'Room', id: string } | null };


export const GuestCheckInDocument = gql`
    mutation GuestCheckIn($roomId: String!, $guestName: String!, $checkInDate: String!, $checkOutDate: String!, $guestLanguage: LanguageCode!) {
  guestCheckIn(
    roomId: $roomId
    guestName: $guestName
    checkOutDate: $checkOutDate
    checkInDate: $checkInDate
    guestLanguage: $guestLanguage
  ) {
    id
  }
}
    `;
export type GuestCheckInMutationFn = ApolloReactCommon.MutationFunction<GuestCheckInMutation, GuestCheckInMutationVariables>;

/**
 * __useGuestCheckInMutation__
 *
 * To run a mutation, you first call `useGuestCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestCheckInMutation, { data, loading, error }] = useGuestCheckInMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      guestName: // value for 'guestName'
 *      checkInDate: // value for 'checkInDate'
 *      checkOutDate: // value for 'checkOutDate'
 *      guestLanguage: // value for 'guestLanguage'
 *   },
 * });
 */
export function useGuestCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestCheckInMutation, GuestCheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GuestCheckInMutation, GuestCheckInMutationVariables>(GuestCheckInDocument, options);
      }
export type GuestCheckInMutationHookResult = ReturnType<typeof useGuestCheckInMutation>;
export type GuestCheckInMutationResult = ApolloReactCommon.MutationResult<GuestCheckInMutation>;
export type GuestCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestCheckInMutation, GuestCheckInMutationVariables>;
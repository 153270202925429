import { Box, Grid, GridItem, HStack, Image } from "@chakra-ui/react";
import { DashboardTile } from "./DashboardTile";
import ScreenTimeImage from "../assets/bm_apps_screen_time.png";
import ChannelTimeImage from "../assets/bm_channel_screen_time.png";
import DashboardEuropeDropdownImage from "../assets/dashboard_europe_dropdown.png";
import AllBrandsDropdownImage from "../assets/all_brands_dropdown.png";
import NetflixImage from "../assets/netflix_image.png";
import { useNavigate } from "react-router-dom";
import { BMDashboardBackground } from "./DashboardBackground";

export const BrandManagerDashboard = () => {
  const navigate = useNavigate();
  const fontSize = 48;
  const DashboardTileData = [
    {
      Main: <Box fontSize={fontSize}>20</Box>,
      Sub: "Total Properties",
      onClick: () => navigate("/sites"),
    },
    {
      Main: <Box fontSize={fontSize}>1720</Box>,
      Sub: "Wave displays",
    },
    {
      Main: <Image src={NetflixImage} height={"70px"} paddingY={"17px"} />,
      Sub: "Most used app",
    },
    {
      Main: (
        <Box>
          <HStack alignItems={"baseline"}>
            <Box fontSize={fontSize}>11</Box>
            <Box fontWeight="bold" marginLeft={"-10px"}>
              h/day
            </Box>
          </HStack>
        </Box>
      ),
      Sub: "Avg. Display runtime",
    },
  ];
  return (
    <Box>
      <BMDashboardBackground />
      <Box
        zIndex={3}
        position={"absolute"}
        top={0}
        left={0}
        width={"100%"}
        height={"100%"}
        p={10}
        pb={0}
      >
        <HStack>
          <img
            src={DashboardEuropeDropdownImage}
            alt="Location"
            width={"10%"}
          />
          <img src={AllBrandsDropdownImage} alt="All Brands" width={"10%"} />
        </HStack>

        <Grid
          templateColumns="repeat(7, 1fr)"
          templateRows="repeat(4, 1fr)"
          gap={5}
          width={"100%"}
          height={"95%"}
        >
          {DashboardTileData.map((tileData, index) => (
            <GridItem
              colSpan={1}
              rowSpan={1}
              marginTop={5}
              key={index}
              onClick={tileData.onClick}
              cursor={"pointer"}
            >
              <DashboardTile {...tileData} />
            </GridItem>
          ))}

          <GridItem colStart={6} colEnd={8} rowSpan={2}>
            <img src={ScreenTimeImage} alt="Screen Time" />
          </GridItem>
          <GridItem colStart={6} colEnd={8} rowStart={3} rowEnd={5}>
            <img src={ChannelTimeImage} alt="Channel Time" />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

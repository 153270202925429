import { SideNavItemData } from "./SideNav";
import DashboardIcon from "../../assets/icons/SideNavDashboard";
import SitesIcon from "../../assets/icons/SideNavSites";
import TasksIcon from "../../assets/icons/SideNavTasks";
import DisplaysIcon from "../../assets/icons/SideNavDisplays";
import ConfigIcon from "../../assets/icons/SideNavConfig";
import LicenseIcon from "../../assets/icons/SideNavLicense";
import CreateIcon from "../../assets/icons/SideNavCreate";
import { useNavigate } from "react-router-dom";
import SideNavHub from "../../assets/icons/SideNavHub";
import SideNavGuest from "../../assets/icons/SideNavGuest";
import SideNavFrontDesk from "../../assets/icons/SideNavFrontDesk";

export { SideNav } from "./SideNav";
export { SideNavItem } from "./SideNavItem";
export type SideNavItemsType = SideNavItemData & {
  includeForUserType?: string[];
};

export const useSideNavData = (userType: string): SideNavItemsType[] => {
  const navigate = useNavigate();

  const sideNavItems: SideNavItemsType[] = [
    {
      key: "dashboard",
      icon: DashboardIcon,
      label: "Dashboard",
      onClick() {
        navigate("/");
      },
      includeForUserType: [
        "admin",
        "device_manager",
        "property_owner",
        "brand_manager",
        "user",
        "claimer",
      ],
    },
    {
      key: "sites",
      icon: SitesIcon,
      label: "Sites",
      onClick() {
        navigate("/sites");
      },
      includeForUserType: [
        "admin",
        "device_manager",
        "property_owner",
        "brand_manager",
      ],
    },
    {
      key: "tasks",
      icon: TasksIcon,
      label: "Tasks",
      onClick() {
        navigate("/tasks");
      },
      includeForUserType: ["admin", "device_manager", "property_owner"],
    },
    {
      key: "frontdesk",
      icon: SideNavFrontDesk,
      label: "Front Desk",
      onClick() {
        navigate("/frontdesk");
      },
      includeForUserType: ["receptionist"],
    },
    {
      key: "hub",
      icon: SideNavHub,
      label: "Hub",
      onClick() {
        navigate("/hub");
      },
      includeForUserType: ["receptionist"],
    },
    {
      key: "guests",
      icon: SideNavGuest,
      label: "Guests",
      onClick() {
        navigate("/guests");
      },
      includeForUserType: ["receptionist"],
    },
    {
      key: "displays",
      icon: DisplaysIcon,
      label: "Displays",
      onClick() {
        navigate("/displays");
      },
      includeForUserType: ["admin", "device_manager", "property_owner"],
    },
    {
      key: "config",
      icon: ConfigIcon,
      label: "Config",
      onClick() {
        navigate("/config");
      },
      includeForUserType: ["admin", "device_manager", "property_owner"],
    },
    {
      key: "license",
      icon: LicenseIcon,
      label: "License",
      onClick() {
        navigate("/license");
      },
      includeForUserType: [
        "admin",
        "device_manager",
        "property_owner",
        "brand_manager",
      ],
    },
    {
      key: "create",
      icon: CreateIcon,
      label: "Create",
      onClick() {
        navigate("/create");
      },
      hasSubMenu: true,
      includeForUserType: [
        "admin",
        "device_manager",
        "property_owner",
        "brand_manager",
      ],
    },
  ];
  return sideNavItems.filter((item: SideNavItemsType) => {
    return item.includeForUserType
      ? item.includeForUserType.includes(userType)
      : true;
  });
};

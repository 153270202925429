import { create } from "zustand";
import { storeDemoData } from "./demoDataStore";

type State = {
  siteName: string;
  siteLogo: File | null;
  siteImage: File | null;
  propertyId: string | null;
};

type Action = {
  setSiteName: (siteName: State["siteName"]) => void;
  setSiteLogo: (siteLogo: State["siteLogo"]) => void;
  setSiteImage: (siteImage: State["siteImage"]) => void;
  setPropertyId: (propertyId: State["propertyId"]) => void;
  resetFields: () => void;
};

export const useSiteDetailsStore = create<State & Action>((set) => ({
  siteName: "Promessa",
  siteLogo: null,
  siteImage: null,
  propertyId: null,

  setSiteName: (siteName) => {
    storeDemoData({ siteName });
    set({ siteName });
  },
  setSiteLogo: (siteLogo) => set({ siteLogo }),
  setSiteImage: (siteImage) => set({ siteImage }),
  setPropertyId: (propertyId) => set({ propertyId }),

  resetFields: () =>
    set({
      siteName: "",
      siteLogo: null,
      siteImage: null,
    }),
}));

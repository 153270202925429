import { gql } from "@apollo/client";
import { AddIcon, ArrowUpIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import DevicesEmptyImage from "../assets/devices_empty.png";
import { EyeBrokenIcon } from "../assets/icons/EyeBrokenIcon";
import { FilterIcon } from "../assets/icons/FilterIcon";
import { SettingsIcon } from "../assets/icons/SettingsIcon";
import { StatsIcon } from "../assets/icons/StatsIcon";
import InviteUsers from "../components/CreateSite/InviteUsers";
import DeviceDetails from "../components/DeviceDetails";
import PagePadding from "../components/PagePadding";
import { InvitationRoles, Tv } from "../graphql/__generated__/types";
import { useAuthStore } from "../store/authUserStore";
import {
  GetTvsByPropertyIdQuery,
  useGetPropertyWithTVsQuery,
  useGetTvsByPropertyIdQuery,
} from "./__generated__/DevicesList.graphql";
import { POLL_INTERVAL } from "../config";
import { useConditionalPolling } from "../hooks/useConditionalPolling";

const tabs: string[] = [
  "Displays",
  "Tasks",
  "Configurations",
  "Users",
  "Licence",
];
export default function DevicesList() {
  const { propertyId: paramPropertyId } = useParams<{ propertyId?: string }>();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const createAddClaimerDrawer = useDisclosure();

  const [propertyId, setPropertyId] = useState(
    paramPropertyId || user?.propertyId || ""
  );

  const { data: property } = useGetPropertyWithTVsQuery({
    variables: { propertyId },
    skip: !propertyId,
  });

  const {
    data: deviceList,
    startPolling,
    stopPolling,
  } = useGetTvsByPropertyIdQuery({
    variables: { propertyId },
    skip: !propertyId,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  useConditionalPolling({
    startPolling,
    stopPolling,
    pollInterval: POLL_INTERVAL,
  });

  useEffect(() => {
    if (!paramPropertyId && user?.propertyId) {
      navigate(`/displays/${user.propertyId}`, { replace: true });
    }
    setPropertyId(paramPropertyId || user?.propertyId || "");
  }, [paramPropertyId, user?.propertyId, navigate]);

  return (
    <PagePadding>
      <VStack
        spacing={5}
        align="left"
        bgColor="white"
        p={4}
        borderRadius={12}
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
      >
        <TableTopBar
          propertyName={property?.property?.name || ""}
          createAddClaimerDrawer={createAddClaimerDrawer}
          propertyId={propertyId}
        />
        <Tabs align="start" variant="unstyled">
          <TabList>
            {tabs.map((tab) => (
              <Tab width="200px" _selected={{ color: "blue.500" }} key={tab}>
                {tab}
              </Tab>
            ))}
          </TabList>
          <TabIndicator
            height="2px"
            bg="blue.400"
            width="150px"
            borderRadius="3px"
          />
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab}>
                {deviceList?.TVsByPropertyId?.length ? (
                  <DevicesFilledTable devicesList={deviceList} />
                ) : (
                  <DevicesEmptyView
                    createAddClaimerDrawer={createAddClaimerDrawer}
                    propertyId={propertyId}
                  />
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </VStack>
    </PagePadding>
  );
}

interface DevicesFilledTableProps {
  devicesList: GetTvsByPropertyIdQuery;
}

function DevicesFilledTable({ devicesList }: DevicesFilledTableProps) {
  return (
    <HStack align="top" width="100%" spacing={10}>
      <TableContainer width="100%">
        <Table>
          <Thead
            sx={{
              th: {
                textTransform: "none",
                color: "black",
                fontSize: "15px",
              },
            }}
          >
            <Tr>
              <Th>
                Room
                <Icon as={ArrowUpIcon} w={4} h={4} ml={2} />
              </Th>
              <Th>Display</Th>
              <Th>Config file</Th>
              <Th>RC battery</Th>
              <Th>Power</Th>
              <Th>Status</Th>
              <Th>Preview</Th>
            </Tr>
          </Thead>
          <Tbody>
            {devicesList?.TVsByPropertyId?.length &&
              devicesList?.TVsByPropertyId.slice()
                .sort((a, b) =>
                  a?.isFake === b?.isFake ? 0 : a?.isFake ? 1 : -1
                )
                .map(
                  (device, index) =>
                    device && (
                      <DeviceListRow
                        device={device}
                        key={device.id}
                        index={index}
                      />
                    )
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </HStack>
  );
}

interface DeviceListRowProps {
  device: Tv;
  index: number;
}

function DeviceListRow({ device, index }: DeviceListRowProps) {
  const createDeviceDetailsPopup = useDisclosure();
  return (
    <Tr>
      <Td w="200px">
        <Text fontWeight="600" fontSize="16px">
          Guest Room {device?.roomId}
        </Text>
      </Td>
      <Td>Philips S{index + 1}</Td>
      <Td>
        <Text fontWeight="600" fontSize="16px">
          {device.configFile}
        </Text>
        <Text fontSize="14px" mt={1}>
          {device.configInstalledDate &&
            "Installed on " + format(device.configInstalledDate, "dd MMM yyyy")}
        </Text>
      </Td>
      <Td>OK</Td>
      <Td>ON</Td>
      <Td>
        <Box
          backgroundColor="#41B546"
          color="white"
          width="80%"
          p={2}
          borderRadius={10}
          textAlign="center"
        >
          Stable
        </Box>
      </Td>
      <Td>
        <IconButton
          aria-label="ViewDeviceDetails"
          variant="ghost"
          icon={<EyeBrokenIcon />}
          onClick={createDeviceDetailsPopup.onOpen}
        />
        {device && !device.isFake && (
          <DeviceDetails
            device={device}
            isOpen={createDeviceDetailsPopup.isOpen}
            onClose={createDeviceDetailsPopup.onClose}
          />
        )}
      </Td>
    </Tr>
  );
}

interface DevicesEmptyViewProps {
  createAddClaimerDrawer: UseDisclosureReturn;
  propertyId: string;
}

function DevicesEmptyView({
  createAddClaimerDrawer,
  propertyId,
}: DevicesEmptyViewProps) {
  return (
    <VStack spacing={5} width="100%" mt={20}>
      <Image src={DevicesEmptyImage} height="250px" />
      <Text fontWeight="600" fontSize="14px">
        Uh ho! There are no displays
      </Text>
      {propertyId.length && (
        <AddClaimer
          isOpen={createAddClaimerDrawer.isOpen}
          onClose={createAddClaimerDrawer.onClose}
          propertyId={propertyId}
        />
      )}
      <Text color="grey" fontWeight="400" fontSize="14px">
        If you have already added a claimer, We will notify you as soon as any
        display is claimed by them!
      </Text>
    </VStack>
  );
}

interface TableTopBarProps {
  propertyName: string;
  createAddClaimerDrawer: UseDisclosureReturn;
  propertyId: string;
}

function TableTopBar({
  propertyName,
  createAddClaimerDrawer,
  propertyId,
}: TableTopBarProps) {
  return (
    <HStack justifyContent="space-between" alignItems="center" width="100%">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/sites" fontWeight={600}>
            All site
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink isCurrentPage>{propertyName}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack spacing={5}>
        <Button rightIcon={<FilterIcon />} variant="ghost">
          Filter
        </Button>
        <Button rightIcon={<SettingsIcon />} variant="ghost">
          Customize
        </Button>
        <Button rightIcon={<StatsIcon />} variant="ghost">
          Overview Stats
        </Button>
      </HStack>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="blue"
        borderRadius={50}
        px={10}
        py={0}
        size="sm"
        onClick={createAddClaimerDrawer.onOpen}
      >
        Invite User
      </Button>
      {propertyId.length && (
        <AddClaimer
          isOpen={createAddClaimerDrawer.isOpen}
          onClose={createAddClaimerDrawer.onClose}
          propertyId={propertyId}
        />
      )}
    </HStack>
  );
}

interface AddClaimerProps {
  isOpen: boolean;
  onClose: () => void;
  propertyId: string;
}

function AddClaimer({ isOpen, onClose, propertyId }: AddClaimerProps) {
  const btnRef = useRef(null);

  const rolesToInvite = [
    {
      role: InvitationRoles.Claimer,
      roleName: "Claimer",
    },
  ];

  return (
    <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay bg="blackAlpha.200" />
      <DrawerContent minW={500}>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom={2} fontWeight="bold">
          Invite User
        </DrawerHeader>
        <DrawerBody>
          <Container display="flex" flexDirection="column" p={0} minH="62vh">
            <InviteUsers
              propertyId={propertyId}
              rolesToInvite={rolesToInvite}
            />
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

DevicesList.graphql = {
  queries: {
    GetTvsByPropertyId: gql`
      query GetTvsByPropertyId($propertyId: String!) {
        TVsByPropertyId(propertyId: $propertyId) {
          id
          thingId
          model
          roomId
          propertyId
          claimQRCode
          claimState
          cloneState
          cloneProgress
          isFake
          createdAt
          updatedAt
          serialNumber
          ethernetMacAddress
          ipAddress
          claimDate
          configFile
          configInstalledDate
        }
      }
    `,
    GetPropertyWithTVs: gql`
      query GetPropertyWithTVs($propertyId: String!) {
        property(id: $propertyId) {
          id
          name
          description
          logoUrl
          imageUrl
          tvs {
            id
            thingId
            model
            roomId
            propertyId
            claimQRCode
            claimState
            cloneState
            cloneProgress
            isFake
            createdAt
            updatedAt
            serialNumber
            ethernetMacAddress
            ipAddress
            claimDate
            configFile
            configInstalledDate
          }
          isFake
          createdBy
          createdByUser {
            id
            name
            email
            displayPicture
            propertyId
            userType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    `,
  },
};

/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  userType: Types.UserType;
  displayPicture?: Types.InputMaybe<Types.Scalars['String']['input']>;
  password: Types.Scalars['String']['input'];
  confirmPassword: Types.Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename: 'Mutation', createUser?: { __typename: 'UserWithTokens', user: { __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string }, tokens: { __typename: 'UserTokens', accessToken: string } } | null };

export type DeleteUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename: 'Mutation', deleteUser?: boolean | null };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  displayPicture?: Types.InputMaybe<Types.Scalars['String']['input']>;
  userType?: Types.InputMaybe<Types.UserType>;
}>;


export type UpdateUserMutation = { __typename: 'Mutation', updateUser?: { __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string } | null };

export type ResetDemoMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ResetDemoMutation = { __typename: 'Mutation', resetDemo?: boolean | null };

export type GetUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename: 'Query', users?: Array<{ __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string } | null> | null };


export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!, $userType: UserType!, $displayPicture: String, $password: String!, $confirmPassword: String!) {
  createUser(
    name: $name
    email: $email
    displayPicture: $displayPicture
    userType: $userType
    password: $password
    confirmPassword: $confirmPassword
  ) {
    user {
      id
      name
      email
      displayPicture
      propertyId
      userType
      createdAt
      updatedAt
    }
    tokens {
      accessToken
    }
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      userType: // value for 'userType'
 *      displayPicture: // value for 'displayPicture'
 *      password: // value for 'password'
 *      confirmPassword: // value for 'confirmPassword'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $name: String, $displayPicture: String, $userType: UserType) {
  updateUser(
    id: $id
    name: $name
    displayPicture: $displayPicture
    userType: $userType
  ) {
    id
    name
    email
    displayPicture
    propertyId
    userType
    createdAt
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      displayPicture: // value for 'displayPicture'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ResetDemoDocument = gql`
    mutation ResetDemo {
  resetDemo
}
    `;
export type ResetDemoMutationFn = ApolloReactCommon.MutationFunction<ResetDemoMutation, ResetDemoMutationVariables>;

/**
 * __useResetDemoMutation__
 *
 * To run a mutation, you first call `useResetDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDemoMutation, { data, loading, error }] = useResetDemoMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetDemoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetDemoMutation, ResetDemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetDemoMutation, ResetDemoMutationVariables>(ResetDemoDocument, options);
      }
export type ResetDemoMutationHookResult = ReturnType<typeof useResetDemoMutation>;
export type ResetDemoMutationResult = ApolloReactCommon.MutationResult<ResetDemoMutation>;
export type ResetDemoMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetDemoMutation, ResetDemoMutationVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users {
    id
    name
    email
    displayPicture
    propertyId
    userType
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "MessageIcon",
  viewBox: "0 0 21 18",
  defaultProps: {
    fill: "none",
    height: "21",
    width: "18",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.0703125C3.41015 0.0703125 0.5 2.98046 0.5 6.57031C0.5 10.1602 3.41015 13.0703 7 13.0703H7.5V16.0703C7.5 16.4748 7.74364 16.8394 8.11732 16.9942C8.49099 17.149 8.92111 17.0634 9.20711 16.7774L12.9142 13.0703H14C17.5899 13.0703 20.5 10.1602 20.5 6.57031C20.5 2.98046 17.5898 0.0703125 14 0.0703125H7ZM2.5 6.57031C2.5 4.08503 4.51472 2.07031 7 2.07031H14C16.4853 2.07031 18.5 4.08503 18.5 6.57031C18.5 9.05559 16.4853 11.0703 14 11.0703H12.5C12.2348 11.0703 11.9804 11.1757 11.7929 11.3632L9.5 13.6561V12.0703C9.5 11.518 9.05228 11.0703 8.5 11.0703H7C4.51472 11.0703 2.5 9.05559 2.5 6.57031ZM6 5.57031H5V7.57031H6H6.01H7.01V5.57031H6.01H6ZM9.5 5.57031H10.5H10.51H11.51V7.57031H10.51H10.5H9.5V5.57031ZM15 5.57031H14V7.57031H15H15.01H16.01V5.57031H15.01H15Z"
      fill="#15191E"
    />
  ),
});

import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../components/Header";
import { SideNavHeaderTemplate } from "../templates/SideNavHeader";
import { SideNav, useSideNavData } from "../components/SideNav";
import { useAuthStore } from "../store/authUserStore";
import useScreenCheck from "../hooks/screenResolution";
import { Box, Text } from "@chakra-ui/react";

export function Layout() {
  const { user } = useAuthStore();
  const sideNavProps = useSideNavData(user?.userType || "");
  const location = useLocation();

  const getCurrentRoute = (path: string) => {
    const segments = path.split("/").filter(Boolean);
    if (segments.length === 0) {
      return "dashboard";
    }
    return segments[0];
  };

  const isAllowed = useScreenCheck();
  if (!isAllowed) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <Text fontSize="xl">
          This screensize is not supported at this moment.
        </Text>
      </Box>
    );
  }

  return (
    <SideNavHeaderTemplate
      header={<Header />}
      sidenav={
        user?.userType !== "admin" && (
          <SideNav
            SideNavItems={sideNavProps}
            activeElementKey={getCurrentRoute(location.pathname)}
          />
        )
      }
    >
      <Outlet />
    </SideNavHeaderTemplate>
  );
}

import { gql } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/authUserStore";
import { useLoginUserMutation } from "./__generated__/useLogin.graphql";
import { getRawApolloClient } from "../graphql/ApolloProvider";

const GET_USER_QUERY = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      name
      email
      displayPicture
      userType
      propertyId
    }
  }
`;

export function useLogin() {
  const navigate = useNavigate();
  const { setUser, setToken, setIsAuthenticated } = useAuthStore();
  const [login, { error, loading }] = useLoginUserMutation();
  const toast = useToast();

  const loginUser = async (email: string, password: string): Promise<void> => {
    try {
      const { data } = await login({
        variables: {
          email,
          password,
        },
      });
      const user = data?.login?.user;
      setToken(data?.login?.tokens.accessToken);

      if (user) {
        setUser({
          userId: user.id,
          name: user.name,
          email: user.email,
          propertyId: user.propertyId,
          displayPicture: user.displayPicture,
          userType: user.userType,
        });
        setIsAuthenticated(true);
        navigate("/");
        toast({
          title: "Logged in successfully",
          description: `Welcome ${user.name}!`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch {}
  };

  const fetchUser = async (userId: string, token: string) => {
    try {
      const { data } = await getRawApolloClient(process.env.REACT_APP_API_URI, {
        authorization: `Bearer ${token}`,
      }).query({
        query: GET_USER_QUERY,
        variables: {
          id: userId,
        },
      });
      return data?.user;
    } catch (err) {
      throw err;
    }
  };

  return {
    loginUser,
    fetchUser,
    loading,
    error,
  };
}

useLogin.graphql = {
  fragments: gql`
    fragment UserLoginPage_user on UserWithTokens {
      user {
        id
        name
        email
        displayPicture
        userType
        propertyId
      }
      tokens {
        accessToken
      }
    }
  `,
  mutations: {
    UserLoginPage: gql`
      mutation LoginUser($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          ...UserLoginPage_user
        }
      }
    `,
  },
};

/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GuestCheckOutMutationVariables = Types.Exact<{
  roomId: Types.Scalars['String']['input'];
  checkOutDate: Types.Scalars['String']['input'];
}>;


export type GuestCheckOutMutation = { __typename: 'Mutation', guestCheckOut?: { __typename: 'Room', id: string } | null };


export const GuestCheckOutDocument = gql`
    mutation GuestCheckOut($roomId: String!, $checkOutDate: String!) {
  guestCheckOut(roomId: $roomId, checkOutDate: $checkOutDate) {
    id
  }
}
    `;
export type GuestCheckOutMutationFn = ApolloReactCommon.MutationFunction<GuestCheckOutMutation, GuestCheckOutMutationVariables>;

/**
 * __useGuestCheckOutMutation__
 *
 * To run a mutation, you first call `useGuestCheckOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestCheckOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestCheckOutMutation, { data, loading, error }] = useGuestCheckOutMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      checkOutDate: // value for 'checkOutDate'
 *   },
 * });
 */
export function useGuestCheckOutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestCheckOutMutation, GuestCheckOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GuestCheckOutMutation, GuestCheckOutMutationVariables>(GuestCheckOutDocument, options);
      }
export type GuestCheckOutMutationHookResult = ReturnType<typeof useGuestCheckOutMutation>;
export type GuestCheckOutMutationResult = ApolloReactCommon.MutationResult<GuestCheckOutMutation>;
export type GuestCheckOutMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestCheckOutMutation, GuestCheckOutMutationVariables>;
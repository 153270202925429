import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "Config",
  viewBox: "0 0 24 25",
  defaultProps: {
    color: "black",
  },
  path: (
    <>
      <path
        d="M2 14.4336C2 10.6626 2 8.77659 3.172 7.60559C4.344 6.43459 6.229 6.43359 10 6.43359H14C17.771 6.43359 19.657 6.43359 20.828 7.60559C21.999 8.77759 22 10.6626 22 14.4336C22 18.2046 22 20.0906 20.828 21.2616C19.656 22.4326 17.771 22.4336 14 22.4336H10C6.229 22.4336 4.343 22.4336 3.172 21.2616C2.001 20.0896 2 18.2046 2 14.4336Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.333 13.1006L10.667 15.7666M13.333 13.1006C14.07 13.8376 15.263 13.8366 16 13.0996M13.333 13.1006C12.9797 12.7469 12.7812 12.2665 12.7812 11.7666C12.7812 11.2667 12.9797 10.7872 13.333 10.4336M10.667 15.7666C11.403 16.5036 11.403 17.6966 10.667 18.4336M10.667 15.7666C10.3133 15.413 9.83365 15.2143 9.3335 15.2143C8.83335 15.2143 8.35369 15.413 8 15.7666M9 3.43359L12 6.43359L16 2.43359"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
});

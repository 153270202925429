import { createIcon } from "@chakra-ui/react";

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "none",
    height: "5",
    width: "5",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.99609C7.58172 3.99609 4 7.57782 4 11.9961C4 16.4144 7.58172 19.9961 12 19.9961C16.4183 19.9961 20 16.4144 20 11.9961C20 7.57782 16.4183 3.99609 12 3.99609ZM2 11.9961C2 6.47325 6.47715 1.99609 12 1.99609C17.5228 1.99609 22 6.47325 22 11.9961C22 17.5189 17.5228 21.9961 12 21.9961C6.47715 21.9961 2 17.5189 2 11.9961ZM13 16.4961V17.4961V17.5011V18.5011H11V17.5011V17.4961V16.4961H13ZM10 8.97884C10 8.49574 10.2017 8.1639 10.5301 7.92231C10.8875 7.65937 11.4177 7.4961 12 7.49609C12.5823 7.49609 13.1125 7.65936 13.4699 7.92229C13.7983 8.16388 14 8.49572 14 8.97884C14 9.49182 13.873 9.75541 13.7327 9.94154C13.5694 10.1582 13.3578 10.3209 13.0032 10.5936L13.0032 10.5936L12.8892 10.6813C12.5017 10.9802 11.9985 11.3853 11.615 12.0142C11.2215 12.6594 11 13.4582 11 14.4961V15.4961H13V14.4961C13 13.7562 13.1535 13.3327 13.3225 13.0555C13.5015 12.762 13.7483 12.5445 14.1108 12.2649C14.1476 12.2365 14.1868 12.2067 14.2279 12.1754L14.2279 12.1754C14.5502 11.9303 14.9907 11.5953 15.3298 11.1455C15.752 10.5854 16 9.89115 16 8.97884C16 7.80679 15.4517 6.89725 14.6551 6.31125C13.8875 5.7466 12.9177 5.49609 12 5.49609C11.0823 5.4961 10.1125 5.74663 9.34493 6.31127C8.54835 6.89726 8 7.8068 8 8.97884V9.97884H10V8.97884Z"
      fill="#15191E"
    />
  ),
});

import { create } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";

export interface AuthUser {
  userId: string;
  name: string;
  email: string;
  displayPicture?: string | null;
  userType: string;
  propertyId?: string | null;
}

export type AuthState = {
  isAuthenticated: boolean;
  token: string | undefined;
  user: AuthUser | null;
};

export type AuthAction = {
  setIsAuthenticated: (isAuthenticated: AuthState["isAuthenticated"]) => void;
  setUser: (user: AuthState["user"]) => void;
  setToken: (token: AuthState["token"]) => void;
  logout: () => void;
};

const persistentStorage: StateStorage = {
  getItem: (key) => {
    return localStorage.getItem(key);
  },
  setItem: (key, newValue): void => {
    localStorage.setItem(key, newValue);
  },
  removeItem: (key): void => {
    localStorage.removeItem(key);
  },
};

const storageOptions = {
  name: "auth-storage",
  storage: createJSONStorage<AuthState & AuthAction>(() => persistentStorage),
};

export const useAuthStore = create(
  persist<AuthState & AuthAction>(
    (set) => ({
      isAuthenticated: !!localStorage.getItem("accessToken"),
      token: localStorage.getItem("accessToken") || "",
      user: JSON.parse(localStorage.getItem("user") || "null"),

      setIsAuthenticated: (value) => set(() => ({ isAuthenticated: value })),
      setUser: (user) => set(() => ({ user })),
      setToken: (token) => set(() => ({ token })),

      logout: () => {
        set({
          isAuthenticated: false,
          user: null,
          token: "",
        });
      },
    }),
    storageOptions
  )
);

import { gql } from "@apollo/client";
import { AddIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilterIcon } from "../assets/icons/FilterIcon";
import { MapIcon } from "../assets/icons/MapIcon";
import { SettingsIcon } from "../assets/icons/SettingsIcon";
import { StatsIcon } from "../assets/icons/StatsIcon";
import AllSites_MapView from "../components/AllSites_MapView";
import AllSites_Stats from "../components/AllSites_Stats";
import CreateSite from "../components/CreateSite/CreateSite";
import {
  InvitationRoles,
  PropertyWithTVs,
} from "../graphql/__generated__/types";
import { useGetPropertiesQuery } from "./__generated__/AllSites.graphql";
import InviteUsers from "../components/CreateSite/InviteUsers";
import PagePadding from "../components/PagePadding";
import DefaultSiteLogo from "../assets/default_sitelogo.png";
import { useAuthStore } from "../store/authUserStore";
import { POLL_INTERVAL } from "../config";
import { useConditionalPolling } from "../hooks/useConditionalPolling";
import { useSiteDetailsStore } from "../store/newSiteCreate";

interface TableTopBarProps {
  activeView: string | null;
  setActiveView: React.Dispatch<React.SetStateAction<string | null>>;
}

interface PaneSwitcherProps {
  activeView: string | null;
}

interface PropertyRowProps {
  property: PropertyWithTVs;
}

const rolesToInvite = [
  { role: InvitationRoles.BrandManager, roleName: "Brand Manager" },
  { role: InvitationRoles.Receptionist, roleName: "Receptionist" },
];

export function AllSites() {
  const { isAuthenticated, user } = useAuthStore();
  const {
    data: propertiesData,
    startPolling,
    stopPolling,
  } = useGetPropertiesQuery({
    skip: !isAuthenticated,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
  useConditionalPolling({
    startPolling,
    stopPolling,
    pollInterval: POLL_INTERVAL,
  });
  const setPropertyId = useSiteDetailsStore((state) => state.setPropertyId);

  useEffect(() => {
    if (!propertiesData || !propertiesData.properties) return;
    propertiesData.properties.map((property) => {
      if (!property?.isFake) setPropertyId(property?.id || "");
    });
  }, [propertiesData]);

  const [activeView, setActiveView] = useState<string | null>(null);

  const sortedProperties = propertiesData?.properties
    ?.slice()
    .sort((a, b) => (a?.isFake === b?.isFake ? 0 : a?.isFake ? 1 : -1));

  return (
    <PagePadding>
      <VStack
        spacing={3}
        bgColor="white"
        p={4}
        borderRadius={12}
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.25)"
      >
        <TableTopBar setActiveView={setActiveView} activeView={activeView} />

        <HStack align="top" width="100%" spacing={3}>
          <TableContainer width="100%">
            <Table>
              <Thead
                sx={{
                  th: {
                    textTransform: "none",
                    color: "black",
                    fontSize: "15px",
                  },
                }}
              >
                <Tr>
                  <Th>
                    Site
                    <Icon as={ArrowUpIcon} w={4} h={4} ml={2} />
                  </Th>
                  <Th>Country</Th>
                  <Th>Chain</Th>
                  <Th>Brand</Th>
                  {["device_manager", "property_owner", "brand_manager"].map(
                    (userType) =>
                      userType != user?.userType && (
                        <Th key={userType}>
                          {userType
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </Th>
                      )
                  )}
                  <Th>Displays</Th>
                  <Th>Overview status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedProperties?.map((property) => {
                  return (
                    property && (
                      <PropertyRow property={property} key={property.id} />
                    )
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <PaneSwitcher activeView={activeView} />
        </HStack>
      </VStack>
    </PagePadding>
  );
}

function TableTopBar({ setActiveView, activeView }: TableTopBarProps) {
  const createSiteModal = useDisclosure();
  const createInviteModal = useDisclosure();
  const { user } = useAuthStore();

  const userButton = (() => {
    if (!user) return null;
    switch (user.userType) {
      case "user":
      case "device_manager":
      case "admin":
        return {
          label: "Create new site",
          onClick: createSiteModal.onOpen,
        };
      case "property_owner":
        return {
          label: "Invite users",
          onClick: createInviteModal.onOpen,
        };
      case "brand_manager":
        return {
          label: "Create campaign",
          onClick: () => {},
        };
      default:
        return null;
    }
  })();

  const resetActiveView = () => setActiveView(null);
  const toggleActiveView = (viewName: string) => () => {
    setActiveView((prevPane) => (prevPane === viewName ? null : viewName));
  };

  return (
    <HStack justifyContent="space-between" alignItems="center" width="100%">
      <Text fontSize={20} fontWeight={700}>
        All Sites
      </Text>
      <HStack spacing={5}>
        <IconButtonOption
          label="Filter"
          icon={<FilterIcon />}
          onClick={resetActiveView}
        />
        <IconButtonOption
          label="Customize"
          icon={<SettingsIcon />}
          onClick={resetActiveView}
        />
        <IconButtonOption
          label="Map View"
          icon={<MapIcon />}
          onClick={toggleActiveView("maps")}
          isActive={activeView === "maps"}
        />
        <IconButtonOption
          label="Overview Stats"
          icon={<StatsIcon />}
          onClick={toggleActiveView("stats")}
          isActive={activeView === "stats"}
        />
      </HStack>
      {userButton && (
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          borderRadius={50}
          px={10}
          py={0}
          size="sm"
          onClick={userButton.onClick}
        >
          {userButton.label}
        </Button>
      )}
      <CreateSite
        isOpen={createSiteModal.isOpen}
        onClose={createSiteModal.onClose}
      />
      {user?.propertyId && (
        <InviteUser
          isOpen={createInviteModal.isOpen}
          onClose={createInviteModal.onClose}
          propertyId={user.propertyId}
        />
      )}
    </HStack>
  );
}

interface IconButtonOptionProps {
  label: string;
  icon: ReactElement;
  onClick: () => void;
  isActive?: boolean;
}

function IconButtonOption({
  label,
  icon,
  onClick,
  isActive = false,
}: IconButtonOptionProps) {
  return (
    <Button
      rightIcon={icon}
      variant="ghost"
      _hover={{ bg: "#BADDFF" }}
      bg={isActive ? "#BADDFF" : "none"}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

function PropertyRow({ property }: PropertyRowProps) {
  const navigate = useNavigate();
  const claimedTVsCount =
    property.tvs?.filter((tv) => tv && tv.claimState === "claimed").length ?? 0;
  const { user } = useAuthStore();

  return (
    <Tr
      style={{ cursor: property.isFake ? "default" : "pointer" }}
      onClick={() => {
        if (!property.isFake) navigate(`/displays/${property.id}`);
      }}
    >
      <Td>
        <HStack spacing={2} alignItems="center">
          <Image
            src={property.logoUrl || DefaultSiteLogo}
            h={35}
            w={35}
            borderRadius="100"
            fit="cover"
          />
          <Text>{property.name}</Text>
        </HStack>
      </Td>
      <Td>{property.country || "USA"}</Td>
      <Td>{property.chain || "---"}</Td>
      <Td>{property.brand || "---"}</Td>
      {property.propertyUsers && property.propertyUsers.length > 0 ? (
        ["device_manager", "property_owner", "brand_manager"].map(
          (userType) => {
            const matchingUser = property.propertyUsers?.find(
              (user) => user?.userType === userType
            );
            return (
              userType !== user?.userType && (
                <Td key={userType}>
                  <Text>{matchingUser?.name}</Text>
                  <Text>{matchingUser?.email}</Text>
                </Td>
              )
            );
          }
        )
      ) : (
        <>
          <Td></Td>
          <Td></Td>
        </>
      )}
      <Td textAlign="center">
        <Box textAlign="center" w={70}>
          <Text fontWeight="500" fontSize={30} mb={1}>
            {String(claimedTVsCount || property.displaysClaimed || 0).padStart(
              2,
              "0"
            )}
          </Text>
          <Text color="#757575">Claimed</Text>
        </Box>
      </Td>
      <Td w={100}>
        <Box
          backgroundColor={
            property.overviewStatus === "Attention" ? "#F2A40E" : "#41B546"
          }
          color="white"
          width="150px"
          p={2}
          borderRadius={10}
          textAlign="center"
        >
          {property.overviewStatus || "Stable"}
        </Box>
      </Td>
    </Tr>
  );
}

const PaneSwitcher = ({ activeView }: PaneSwitcherProps) => {
  switch (activeView) {
    case "stats":
      return <AllSites_Stats />;
    case "maps":
      return <AllSites_MapView />;
    default:
      return null;
  }
};

interface InviteUserProps {
  isOpen: boolean;
  onClose: () => void;
  propertyId: string;
}

function InviteUser({ isOpen, onClose, propertyId }: InviteUserProps) {
  const btnRef = useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader paddingBottom={2} fontWeight="bold">
          Invite User
        </ModalHeader>
        <ModalBody>
          <Container display="flex" flexDirection="column" p={0} minH="62vh">
            <InviteUsers
              propertyId={propertyId}
              rolesToInvite={rolesToInvite}
            />
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

AllSites.graphql = {
  queries: {
    GetProperties: gql`
      query GetProperties {
        properties {
          id
          name
          description
          logoUrl
          imageUrl
          chain
          brand
          solutionProviderName
          solutionProviderEmail
          contentManagerName
          contentManagerEmail
          displaysClaimed
          proLicenseCount
          liteLicenseCount
          country
          city
          overviewStatus
          tvs {
            id
            thingId
            model
            roomId
            propertyId
            claimQRCode
            claimState
            cloneState
            cloneProgress
            isFake
            createdAt
            updatedAt
          }
          isFake
          createdBy
          createdByUser {
            id
            name
            email
            displayPicture
            propertyId
            userType
            createdAt
            updatedAt
          }
          propertyUsers {
            id
            name
            email
            displayPicture
            propertyId
            userType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    `,
  },
};

import { Box, FormControl, Input } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const SearchBar = () => {
  return (
    <Box
      className="flex"
      zIndex="0"
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100%"
    >
      <FormControl
        as="form"
        display="flex"
        overflow="hidden"
        flex="1"
        alignItems="center"
        pr="4"
        pl="5"
        h="100%"
        w="100%"
        bg="white"
        border="1px solid"
        borderColor="slate.500"
        rounded="full"
      >
        <SearchIcon />
        <Input
          id="searchInput"
          type="search"
          placeholder="Search"
          flex="1"
          ml="3"
          py="2"
          h="100%"
          fontSize="base"
          fontWeight="350"
          color="slate.600"
          bg="transparent"
          border="none"
          outline="none"
          variant={"unstyled"}
          _placeholder={{ color: "slate.400" }}
        />
      </FormControl>
    </Box>
  );
};

export default SearchBar;

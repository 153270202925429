import { createIcon } from "@chakra-ui/react";

export const MapIcon = createIcon({
  viewBox: "0 0 18 21",
  displayName: "MapIcon",
  defaultProps: {
    fill: "black",
    height: "18",
    width: "21",
    clipRule: "evenodd",
    fillRule: "evenodd",
  },
  path: (
    <svg>
      <path d="M9.0001 8.76265C9.63662 8.76265 10.2471 8.50979 10.6972 8.0597C11.1472 7.60962 11.4001 6.99917 11.4001 6.36265C11.4001 5.72613 11.1472 5.11568 10.6972 4.66559C10.2471 4.2155 9.63662 3.96265 9.0001 3.96265C8.36358 3.96265 7.75313 4.2155 7.30304 4.66559C6.85295 5.11568 6.6001 5.72613 6.6001 6.36265C6.6001 6.99917 6.85295 7.60962 7.30304 8.0597C7.75313 8.50979 8.36358 8.76265 9.0001 8.76265ZM9.0001 5.16265C9.31836 5.16265 9.62358 5.28908 9.84863 5.51412C10.0737 5.73916 10.2001 6.04439 10.2001 6.36265C10.2001 6.68091 10.0737 6.98613 9.84863 7.21117C9.62358 7.43622 9.31836 7.56265 9.0001 7.56265C8.68184 7.56265 8.37661 7.43622 8.15157 7.21117C7.92653 6.98613 7.8001 6.68091 7.8001 6.36265C7.8001 6.04439 7.92653 5.73916 8.15157 5.51412C8.37661 5.28908 8.68184 5.16265 9.0001 5.16265Z" />
      <path d="M3.6001 6.10575C3.6001 9.31335 6.7837 14.7625 9.0001 14.7625C11.2177 14.7625 14.4001 9.31335 14.4001 6.10575C14.4001 2.94255 11.9917 0.362549 9.0001 0.362549C6.0085 0.362549 3.6001 2.94255 3.6001 6.10575ZM13.2001 6.10575C13.2001 8.73735 10.3825 13.5625 9.0001 13.5625C7.6189 13.5625 4.8001 8.73855 4.8001 6.10575C4.8001 3.58695 6.6901 1.56255 9.0001 1.56255C11.3101 1.56255 13.2001 3.58695 13.2001 6.10575Z" />
      <path d="M13.122 9.53048C12.9786 9.46874 12.8648 9.35349 12.805 9.20921C12.7451 9.06493 12.7439 8.90301 12.8016 8.75784C12.8592 8.61268 12.9712 8.49573 13.1137 8.43183C13.2563 8.36794 13.4181 8.36214 13.5648 8.41568C14.0528 8.60922 14.4925 8.90714 14.8532 9.28856C15.2139 9.66998 15.4868 10.1257 15.6528 10.6237L17.2536 15.4237C17.4342 15.965 17.4834 16.5416 17.3973 17.1057C17.3112 17.6698 17.0922 18.2054 16.7584 18.6683C16.4246 19.1311 15.9855 19.508 15.4774 19.7678C14.9693 20.0276 14.4067 20.1629 13.836 20.1625H4.16403C3.59357 20.1625 3.03126 20.0269 2.52347 19.767C2.01568 19.507 1.57693 19.1301 1.2434 18.6673C0.90986 18.2045 0.691083 17.6691 0.605096 17.1051C0.519109 16.5412 0.568375 15.9648 0.748832 15.4237L2.34843 10.6237C2.51734 10.1167 2.79697 9.65374 3.16705 9.2683C3.53713 8.88286 3.98837 8.58464 4.48803 8.39528C4.5617 8.3673 4.64016 8.35412 4.71893 8.35647C4.7977 8.35882 4.87523 8.37666 4.9471 8.40897C5.01898 8.44129 5.08378 8.48745 5.13782 8.5448C5.19185 8.60216 5.23406 8.6696 5.26203 8.74328C5.29 8.81695 5.30319 8.89541 5.30084 8.97417C5.29849 9.05294 5.28065 9.13048 5.24833 9.20235C5.21602 9.27422 5.16986 9.33902 5.1125 9.39306C5.05514 9.4471 4.9877 9.4893 4.91403 9.51728C4.5805 9.64345 4.27928 9.84236 4.03226 10.0995C3.78525 10.3567 3.59865 10.6657 3.48603 11.0041L1.88643 15.8041C1.76626 16.1649 1.73355 16.5491 1.79099 16.925C1.84843 17.3009 1.99438 17.6578 2.21681 17.9662C2.43924 18.2747 2.73179 18.5259 3.07033 18.6991C3.40888 18.8723 3.78375 18.9626 4.16403 18.9625H13.8384C14.2188 18.9624 14.5936 18.872 14.9321 18.6987C15.2707 18.5253 15.5631 18.274 15.7855 17.9654C16.0078 17.6568 16.1536 17.2998 16.2108 16.9239C16.2681 16.5479 16.2352 16.1636 16.1148 15.8029L14.5164 11.0029C14.4057 10.671 14.2237 10.3674 13.9832 10.1132C13.7428 9.85911 13.4497 9.66062 13.1244 9.53168" />
    </svg>
  ),
});

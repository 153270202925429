import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "CustomIcon2",
  viewBox: "0 0 24 24",
  defaultProps: {
    color: "black", // Default color is set to black
  },
  path: (
    <path
      d="M17.325 12L22.05 7.275C22.65 6.675 22.65 5.775 22.05 5.175L18.9 2.025C18.3 1.425 17.4 1.425 16.8 2.025L12 6.675L7.275 1.95C6.675 1.35 5.775 1.35 5.175 1.95L1.95 5.1C1.35 5.7 1.35 6.6 1.95 7.2L6.675 12L1.5 17.175V22.5H6.825L12 17.325L16.725 22.05C17.325 22.65 18.225 22.65 18.825 22.05L21.975 18.9C22.575 18.3 22.575 17.4 21.975 16.8L17.325 12ZM17.85 3L21 6.15L16.275 10.875L13.125 7.725L17.85 3ZM6.15 21H3V17.85L7.725 13.125L10.875 16.275L6.15 21ZM17.85 21L3 6.15L6.15 3L8.775 5.625L7.2 7.2L8.25 8.25L9.825 6.675L12.975 9.825L11.4 11.4L12.45 12.45L14.025 10.875L17.175 14.025L15.75 15.675L16.8 16.725L18.375 15.15L21 17.775L17.85 21Z"
      fill="currentColor"
    />
  ),
});

import axios, { AxiosError } from "axios";

export async function uploadToS3(signedUrl: string, file: File): Promise<void> {
  try {
    const response = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    if (response.status !== 200) {
      console.error(
        "Failed to upload file:",
        response.status,
        response.statusText
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.isAxiosError && axiosError.response) {
      throw new Error(
        `uploading file error: ${axiosError.response.statusText}`
      );
    } else {
      throw new Error(`uploading file error: ${axiosError.message}`);
    }
  }
}

import { ApolloError, gql } from "@apollo/client";
import { useGetSignedUrlMutation } from "./__generated__/getsignedUrl.graphql";
import { AllowedAsset } from "../graphql/__generated__/types";

interface SignedUrlResponse {
  signedUrl: string;
  key: string;
}
export function useGetSignedUrl() {
  const [getSignedUrlMutation, { loading, error }] = useGetSignedUrlMutation();

  const getSignedUrl = async (
    filename: string,
    contentType: string,
    assetType: AllowedAsset = AllowedAsset.Asset
  ): Promise<SignedUrlResponse> => {
    try {
      const response = await getSignedUrlMutation({
        variables: { filename, contentType, assetType },
      });

      if (!response.data || !response.data.getSignedUrl) {
        throw new ApolloError({
          errorMessage: `Failed to get signed URL: ${error?.graphQLErrors
            .map((e) => e.message)
            .join(", ")}`,
        });
      }

      return {
        signedUrl: response.data.getSignedUrl.signedUrl,
        key: response.data.getSignedUrl.key,
      };
    } catch (err) {
      throw err;
    }
  };

  return {
    getSignedUrl,
    loading,
    error,
  };
}

useGetSignedUrl.graphql = {
  getSignedUrl: gql`
    mutation GetSignedUrl(
      $filename: String!
      $contentType: String!
      $assetType: AllowedAsset
    ) {
      getSignedUrl(
        fileName: $filename
        contentType: $contentType
        assetType: $assetType
      ) {
        signedUrl
        key
      }
    }
  `,
};

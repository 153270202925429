import { createIcon } from "@chakra-ui/react";

export const FilterIcon = createIcon({
  displayName: "FilterIcon",
  defaultProps: {
    fill: "black",
    height: "9",
    width: "5",
  },
  viewBox: "0 0 24 15",
  path: (
    <path d="M1.125 0.0625H22.875C23.1734 0.0625 23.4595 0.181026 23.6705 0.392005C23.8815 0.602983 24 0.889131 24 1.1875C24 1.48587 23.8815 1.77202 23.6705 1.983C23.4595 2.19397 23.1734 2.3125 22.875 2.3125H1.125C0.826631 2.3125 0.540483 2.19397 0.329505 1.983C0.118526 1.77202 0 1.48587 0 1.1875C0 0.889131 0.118526 0.602983 0.329505 0.392005C0.540483 0.181026 0.826631 0.0625 1.125 0.0625ZM4.5 7.1875C4.5 6.88913 4.61853 6.60298 4.8295 6.392C5.04048 6.18103 5.32663 6.0625 5.625 6.0625H18.375C18.6734 6.0625 18.9595 6.18103 19.1705 6.392C19.3815 6.60298 19.5 6.88913 19.5 7.1875C19.5 7.48587 19.3815 7.77202 19.1705 7.98299C18.9595 8.19397 18.6734 8.3125 18.375 8.3125H5.625C5.32663 8.3125 5.04048 8.19397 4.8295 7.98299C4.61853 7.77202 4.5 7.48587 4.5 7.1875ZM9 13.1875C9 12.8891 9.11853 12.603 9.3295 12.392C9.54048 12.181 9.82663 12.0625 10.125 12.0625H13.875C14.1734 12.0625 14.4595 12.181 14.6705 12.392C14.8815 12.603 15 12.8891 15 13.1875C15 13.4859 14.8815 13.772 14.6705 13.983C14.4595 14.194 14.1734 14.3125 13.875 14.3125H10.125C9.82663 14.3125 9.54048 14.194 9.3295 13.983C9.11853 13.772 9 13.4859 9 13.1875Z" />
  ),
});

/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type Alert = {
  __typename?: 'Alert';
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  extras?: Maybe<AlertExtras>;
  id: Scalars['String']['output'];
  landingUrl?: Maybe<Scalars['String']['output']>;
  status: AlertStatus;
  targetId: Scalars['String']['output'];
  targetType: AlertTarget;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type AlertExtras = {
  __typename?: 'AlertExtras';
  alertType?: Maybe<AlertTypeEnum>;
  checkInDate?: Maybe<Scalars['String']['output']>;
  checkOutDate?: Maybe<Scalars['String']['output']>;
  claimerUserId?: Maybe<Scalars['String']['output']>;
  claimerUserName?: Maybe<Scalars['String']['output']>;
  cloneUrl?: Maybe<Scalars['String']['output']>;
  guestCountry?: Maybe<CountryCode>;
  guestLanguage?: Maybe<LanguageCode>;
  guestName?: Maybe<Scalars['String']['output']>;
  invitationId?: Maybe<Scalars['String']['output']>;
  isFake?: Maybe<Scalars['Boolean']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  propertyLogo?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  roomCheckInState?: Maybe<Scalars['Boolean']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  thingId?: Maybe<Scalars['String']['output']>;
};

/** Status of alert. */
export enum AlertStatus {
  Read = 'read',
  Unread = 'unread'
}

/** The type of target for the alert. */
export enum AlertTarget {
  Tv = 'tv',
  User = 'user'
}

/** Type of the alert. */
export enum AlertTypeEnum {
  CheckIn = 'checkIn',
  CheckOut = 'checkOut',
  Claimed = 'claimed',
  Claiming = 'claiming',
  Cloning = 'cloning',
  DiffClone = 'diff_clone',
  Invitation = 'invitation',
  Reset = 'reset'
}

/** Allowed type for uploaded files. */
export enum AllowedAsset {
  Asset = 'asset',
  CloneFile = 'clone_file',
  DiffCloneFile = 'diff_clone_file'
}

/** Claim state for TV. */
export enum ClaimState {
  Claimed = 'claimed',
  Unclaimed = 'unclaimed'
}

/** Clone state for TV. */
export enum CloneState {
  CloningEnded = 'cloning_ended',
  CloningStarted = 'cloning_started',
  Default = 'default',
  Error = 'error'
}

export type CloneUrls = {
  __typename?: 'CloneUrls';
  diff: Scalars['String']['output'];
  reset: Scalars['String']['output'];
};

/** Allowed country codes. */
export enum CountryCode {
  Bel = 'BEL',
  Deu = 'DEU',
  Esp = 'ESP',
  Fra = 'FRA',
  Ita = 'ITA',
  Itr = 'ITR',
  Nld = 'NLD'
}

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invitationRole: InvitationRoles;
  isAccepted: Scalars['Boolean']['output'];
  propertyId: Scalars['String']['output'];
  recipientEmail: Scalars['String']['output'];
  senderEmail: Scalars['String']['output'];
  ttl: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

/** The type of user, either regular user or others */
export enum InvitationRoles {
  BrandManager = 'brand_manager',
  Claimer = 'claimer',
  DeviceManager = 'device_manager',
  PropertyOwner = 'property_owner',
  Receptionist = 'receptionist',
  User = 'user'
}

/** Allowed language codes. */
export enum LanguageCode {
  Deu = 'DEU',
  Eng = 'ENG',
  Fra = 'FRA',
  Ita = 'ITA',
  Nld = 'NLD',
  Spa = 'SPA'
}

export type LoginInput = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  TVLogin?: Maybe<TvWithTokens>;
  acceptInvitation?: Maybe<Scalars['Boolean']['output']>;
  claimTV?: Maybe<Tv>;
  createAlert?: Maybe<Alert>;
  createInvitation?: Maybe<Invitation>;
  createProperty?: Maybe<Property>;
  createRoom?: Maybe<Room>;
  createUser?: Maybe<UserWithTokens>;
  deleteInvitation?: Maybe<Scalars['Boolean']['output']>;
  deleteProperty?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  getSignedUrl?: Maybe<SignedUrlResponse>;
  guestCheckIn?: Maybe<Room>;
  guestCheckOut?: Maybe<Room>;
  login?: Maybe<UserWithTokens>;
  markAlertAsRead?: Maybe<Alert>;
  resetDemo?: Maybe<Scalars['Boolean']['output']>;
  updateProperty?: Maybe<Property>;
  updateTVStatus?: Maybe<Tv>;
  updateUser?: Maybe<User>;
};


export type MutationTvLoginArgs = {
  model: Scalars['String']['input'];
  thingId: Scalars['String']['input'];
};


export type MutationAcceptInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationClaimTvArgs = {
  ethernetMacAddress?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  isFake?: Scalars['Boolean']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['String']['input'];
  roomId?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  thingId: Scalars['String']['input'];
};


export type MutationCreateAlertArgs = {
  description: Scalars['String']['input'];
  extras?: InputMaybe<Scalars['JSON']['input']>;
  landingUrl?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['String']['input'];
  targetType: AlertTarget;
  title: Scalars['String']['input'];
};


export type MutationCreateInvitationArgs = {
  invitationRole: InvitationRoles;
  propertyId: Scalars['String']['input'];
  recipientEmail: Scalars['String']['input'];
};


export type MutationCreatePropertyArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isFake?: Scalars['Boolean']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateRoomArgs = {
  checkInState?: Scalars['Boolean']['input'];
  guestName?: InputMaybe<Scalars['String']['input']>;
  isFake?: Scalars['Boolean']['input'];
  propertyId: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  confirmPassword: Scalars['String']['input'];
  displayPicture?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  userType: UserType;
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetSignedUrlArgs = {
  assetType?: InputMaybe<AllowedAsset>;
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type MutationGuestCheckInArgs = {
  checkInDate: Scalars['String']['input'];
  checkOutDate: Scalars['String']['input'];
  guestLanguage: LanguageCode;
  guestName: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationGuestCheckOutArgs = {
  checkOutDate: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMarkAlertAsReadArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdatePropertyArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTvStatusArgs = {
  cloneProgress?: InputMaybe<Scalars['Int']['input']>;
  cloneState?: InputMaybe<CloneState>;
  configFile?: InputMaybe<Scalars['String']['input']>;
  thingId: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  displayPicture?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<UserType>;
};

export type Property = {
  __typename?: 'Property';
  brand?: Maybe<Scalars['String']['output']>;
  chain?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contentManagerEmail?: Maybe<Scalars['String']['output']>;
  contentManagerName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  displaysClaimed?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isFake: Scalars['Boolean']['output'];
  liteLicenseCount?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  overviewStatus?: Maybe<Scalars['String']['output']>;
  proLicenseCount?: Maybe<Scalars['String']['output']>;
  propertyUsers?: Maybe<Array<Maybe<User>>>;
  solutionProviderEmail?: Maybe<Scalars['String']['output']>;
  solutionProviderName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type PropertyWithTVs = {
  __typename?: 'PropertyWithTVs';
  brand?: Maybe<Scalars['String']['output']>;
  chain?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contentManagerEmail?: Maybe<Scalars['String']['output']>;
  contentManagerName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  displaysClaimed?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isFake: Scalars['Boolean']['output'];
  liteLicenseCount?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  overviewStatus?: Maybe<Scalars['String']['output']>;
  proLicenseCount?: Maybe<Scalars['String']['output']>;
  propertyUsers?: Maybe<Array<Maybe<User>>>;
  solutionProviderEmail?: Maybe<Scalars['String']['output']>;
  solutionProviderName?: Maybe<Scalars['String']['output']>;
  tvs?: Maybe<Array<Maybe<Tv>>>;
  updatedAt: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  TVInfo?: Maybe<Tv>;
  TVsByPropertyId?: Maybe<Array<Maybe<Tv>>>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
  cloneUrls?: Maybe<CloneUrls>;
  getReceivedinvitations?: Maybe<Array<Maybe<Invitation>>>;
  getSentinvitations?: Maybe<Array<Maybe<Invitation>>>;
  invitation?: Maybe<Invitation>;
  properties?: Maybe<Array<Maybe<PropertyWithTVs>>>;
  property?: Maybe<PropertyWithTVs>;
  room?: Maybe<Room>;
  rooms?: Maybe<Array<Maybe<Room>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryTvInfoArgs = {
  thingId: Scalars['String']['input'];
};


export type QueryTVsByPropertyIdArgs = {
  propertyId: Scalars['String']['input'];
};


export type QueryAlertsArgs = {
  afterDateTime?: InputMaybe<Scalars['String']['input']>;
  excludeRead?: InputMaybe<Scalars['Boolean']['input']>;
  thingId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvitationArgs = {
  id: Scalars['String']['input'];
};


export type QueryPropertyArgs = {
  id: Scalars['String']['input'];
};


export type QueryRoomArgs = {
  id: Scalars['String']['input'];
  propertyId: Scalars['String']['input'];
};


export type QueryRoomsArgs = {
  propertyId: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Room = {
  __typename?: 'Room';
  checkInDate?: Maybe<Scalars['String']['output']>;
  checkInState: Scalars['Boolean']['output'];
  checkOutDate?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  guestLanguage?: Maybe<LanguageCode>;
  guestName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isFake: Scalars['Boolean']['output'];
  propertyId: Scalars['String']['output'];
  roomId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type Tv = {
  __typename?: 'TV';
  claimDate?: Maybe<Scalars['String']['output']>;
  claimQRCode?: Maybe<Scalars['String']['output']>;
  claimState?: Maybe<ClaimState>;
  cloneProgress?: Maybe<Scalars['Int']['output']>;
  cloneState?: Maybe<CloneState>;
  configFile?: Maybe<Scalars['String']['output']>;
  configInstalledDate?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  ethernetMacAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  isFake: Scalars['Boolean']['output'];
  model: Scalars['String']['output'];
  propertyId?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  thingId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TvTokens = {
  __typename?: 'TVTokens';
  accessToken: Scalars['String']['output'];
};

export type TvWithTokens = {
  __typename?: 'TVWithTokens';
  tokens: TvTokens;
  tv: Tv;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['String']['output'];
  displayPicture?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  propertyId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userType: UserType;
};

export type UserTokens = {
  __typename?: 'UserTokens';
  accessToken: Scalars['String']['output'];
};

/** The type of user. */
export enum UserType {
  Admin = 'admin',
  BrandManager = 'brand_manager',
  Claimer = 'claimer',
  DeviceManager = 'device_manager',
  PropertyOwner = 'property_owner',
  Receptionist = 'receptionist',
  User = 'user'
}

export type UserUpdateInput = {
  displayPicture?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserWithTokens = {
  __typename?: 'UserWithTokens';
  tokens: UserTokens;
  user: User;
};

import { useState } from "react";
import {
  VStack,
  Input,
  Button,
  Text,
  Container,
  Box,
  Image,
} from "@chakra-ui/react";
import BackgroundImage from "../assets/htv_background.jpg";
import WaveLogo from "../assets/htv_logo.png";
import { Form } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { getDemoData, storeDemoData } from "../store/demoDataStore";

export function LoginPage() {
  const demoData = getDemoData();
  const [email, setEmail] = useState(demoData.email || "");
  const [password, setPassword] = useState(demoData.password || "");
  const { loginUser, loading } = useLogin();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    storeDemoData({ email, password });
    await loginUser(email, password);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      backgroundImage={`url(${BackgroundImage})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      overflow="hidden"
    >
      <Container
        // background="#0B5ED7"
        boxShadow="0px 10px 15px -3px rgba(0, 56, 95, 0.1),
    0px 4px 6px -2px rgba(0, 56, 95, 0.05)"
        borderRadius="base"
        maxW="md"
      >
        <Form onSubmit={handleLogin}>
          <VStack spacing={4} padding={10}>
            <Image src={WaveLogo} boxSize={150} alt="logo" />
            <Text color="red"></Text>
            <Input
              placeholder="Email"
              _placeholder={{ color: "white" }}
              value={email}
              color="white"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="Password"
              _placeholder={{ color: "white" }}
              type="password"
              color="white"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              colorScheme="blue"
              isLoading={loading}
              type="submit"
              width="100%"
            >
              Login
            </Button>
          </VStack>
        </Form>
      </Container>
    </Box>
  );
}

import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "SolarTVIcon",
  defaultProps: {
    width: "37",
    height: "37",
    fill: "none",
  },
  viewBox: "0 0 37 37",
  path: (
    <>
      <path
        d="M3.36865 21.5V24.5C3.36865 28.742 3.36865 30.8645 4.68715 32.1815C6.00415 33.5 8.12665 33.5 12.3687 33.5H24.3687M24.3687 33.5C28.6107 33.5 30.7332 33.5 32.0502 32.1815C33.3687 30.8645 33.3687 28.742 33.3687 24.5V18.5C33.3687 14.258 33.3687 12.1355 32.0502 10.8185C30.7332 9.5 28.6107 9.5 24.3687 9.5M24.3687 33.5V21.5M24.3687 9.5H12.3687C8.12665 9.5 6.00415 9.5 4.68715 10.8185C3.72415 11.78 3.46465 13.1705 3.39415 15.5M24.3687 9.5V15.5M13.8687 3.5L18.3687 8.75L22.8687 3.5"
        stroke="black"
        strokeWidth="2.25"
        strokeLinecap="round"
      />
      <path
        d="M30.3687 24.5C30.3687 24.1022 30.2106 23.7206 29.9293 23.4393C29.648 23.158 29.2665 23 28.8687 23C28.4708 23 28.0893 23.158 27.808 23.4393C27.5267 23.7206 27.3687 24.1022 27.3687 24.5C27.3687 24.8978 27.5267 25.2794 27.808 25.5607C28.0893 25.842 28.4708 26 28.8687 26C29.2665 26 29.648 25.842 29.9293 25.5607C30.2106 25.2794 30.3687 24.8978 30.3687 24.5ZM30.3687 18.5C30.3687 18.1022 30.2106 17.7206 29.9293 17.4393C29.648 17.158 29.2665 17 28.8687 17C28.4708 17 28.0893 17.158 27.808 17.4393C27.5267 17.7206 27.3687 18.1022 27.3687 18.5C27.3687 18.8978 27.5267 19.2794 27.808 19.5607C28.0893 19.842 28.4708 20 28.8687 20C29.2665 20 29.648 19.842 29.9293 19.5607C30.2106 19.2794 30.3687 18.8978 30.3687 18.5Z"
        fill="black"
      />
    </>
  ),
});

import { Image } from "@chakra-ui/react";
import brand_manager_background from "../assets/brand_manager_map.png";
import property_owner_background from "../assets/property_owner_map.png";

export const PODashboardBackground = () => {
  return (
    <Image
      src={property_owner_background}
      position={"absolute"}
      width="100%"
      height="100%"
      top={0}
      fit="cover"
      left={0}
      zIndex={2}
    />
  );
};

export const BMDashboardBackground = () => {
  return (
    <Image
      src={brand_manager_background}
      position={"absolute"}
      width="100%"
      height="100%"
      top={0}
      fit="cover"
      left={0}
      zIndex={2}
    />
  );
};

import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "CustomIcon6",
  viewBox: "0 0 24 24",
  defaultProps: {
    color: "black", // Default color is set to black
  },
  path: (
    <>
      <path
        d="M10.47 22C6.478 22 4.481 22 3.24 20.828C2 19.657 2 17.771 2 14V10C2 6.229 2 4.343 3.24 3.172C4.481 2 6.478 2 10.47 2H11.53C15.523 2 17.519 2 18.76 3.172C20 4.343 20 6.229 20 10M7 7H15M7 12H13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14 22C15.838 19.252 20.119 19.102 22 22M20 14.929C20 15.994 19.105 16.857 18 16.857C16.895 16.857 16 15.994 16 14.929C16 13.864 16.895 13 18 13C19.105 13 20 13.864 20 14.929Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
});

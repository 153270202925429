import { createIcon } from "@chakra-ui/react";

export default createIcon({
  displayName: "SideNavHub",
  viewBox: "0 0 18 23",
  defaultProps: {
    fill: "none",
  },
  path: [
    <path
      d="M7 21.4336V14.8636M9 10.4336H9.01M9 6.43359H9.01M11 14.8636V21.4336M12 15.4336C11.1345 14.7845 10.0819 14.4336 9 14.4336C7.91815 14.4336 6.86548 14.7845 6 15.4336M13 10.4336H13.01M13 6.43359H13.01M5 10.4336H5.01M5 6.43359H5.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="1"
    />,
    <path
      d="M15 1.43359H3C1.89543 1.43359 1 2.32902 1 3.43359V19.4336C1 20.5382 1.89543 21.4336 3 21.4336H15C16.1046 21.4336 17 20.5382 17 19.4336V3.43359C17 2.32902 16.1046 1.43359 15 1.43359Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      key="2"
    />,
  ],
});

/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type InviteUsersMutationVariables = Types.Exact<{
  recipientEmail: Types.Scalars['String']['input'];
  invitationRole: Types.InvitationRoles;
  propertyId: Types.Scalars['String']['input'];
}>;


export type InviteUsersMutation = { __typename: 'Mutation', createInvitation?: { __typename: 'Invitation', id: string, senderEmail: string, recipientEmail: string, invitationRole: Types.InvitationRoles, propertyId: string, ttl: string, isAccepted: boolean, createdAt: string, updatedAt: string } | null };


export const InviteUsersDocument = gql`
    mutation InviteUsers($recipientEmail: String!, $invitationRole: InvitationRoles!, $propertyId: String!) {
  createInvitation(
    recipientEmail: $recipientEmail
    invitationRole: $invitationRole
    propertyId: $propertyId
  ) {
    id
    senderEmail
    recipientEmail
    invitationRole
    propertyId
    ttl
    isAccepted
    createdAt
    updatedAt
  }
}
    `;
export type InviteUsersMutationFn = ApolloReactCommon.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      recipientEmail: // value for 'recipientEmail'
 *      invitationRole: // value for 'invitationRole'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = ApolloReactCommon.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
import { extendTheme } from "@chakra-ui/react";

export type Theme = typeof theme;

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

export const theme = extendTheme({
  fonts: {
    heading: `"Neue Frutiger One", sans-serif`,
    body: `"Neue Frutiger One", sans-serif`,
    bold: `"Neue Frutiger One Bold", sans-serif`,
  },
  colors: {
    gray: {
      50: "#F0F4F8",
      75: "#F5F8FB",
      100: "#D9E2EC",
      200: "#BCCCDC",
      300: "#9FB3C8",
      400: "#829AB1",
      500: "#627D98",
      600: "#486581",
      700: "#334E68",
      800: "#243B53",
      900: "#102A43",
    },
    red: {
      25: "#FFF2F2",
      50: "#FFE3E3",
      100: "#FFBDBD",
      200: "#FF9B9B",
      300: "#F86A6A",
      400: "#EF4E4E",
      500: "#E12D39",
      600: "#CF1124",
      700: "#AB091E",
      800: "#8A041A",
      900: "#610316",
    },
    orange: {
      50: "#FFEFE6",
      100: "#FFD3BA",
      200: "#FAB38B",
      300: "#EF8E58",
      400: "#E67635",
      500: "#C65D21",
      600: "#AB4E19",
      700: "#8C3D10",
      800: "#77340D",
      900: "#572508",
    },
    yellow: {
      50: "#FFFBEA",
      100: "#FFF3C4",
      200: "#FCE588",
      300: "#FADB5F",
      400: "#F7C948",
      500: "#F0B429",
      600: "#DE911D",
      700: "#CB6E17",
      800: "#B44D12",
      900: "#8D2B0B",
    },
    green: {
      50: "#EFFCF6",
      100: "#C6F7E2",
      200: "#8EEDC7",
      300: "#65D6AD",
      400: "#3EBD93",
      500: "#27AB83",
      600: "#199473",
      700: "#147D64",
      800: "#0C6B58",
      900: "#014D40",
    },
    teal: {
      50: "#E6FFFA",
      100: "#B2F5EA",
      200: "#81E6D9",
      300: "#4FD1C5",
      400: "#38B2AC",
      500: "#319795",
      600: "#2C7A7B",
      700: "#285E61",
      800: "#234E52",
      900: "#1D4044",
    },
    blue: {
      25: "#FAFCFF",
      50: "#F1F8FE",
      100: "#D5E9FB",
      200: "#A7D0F6",
      300: "#79B7F1",
      400: "#4B9EEC",
      500: "#1D85E8",
      600: "#146BBD",
      700: "#0F5291",
      800: "#0A3761",
      900: "#092E51",
      950: "#002457",
    },
    lightBlue: {
      50: "#EBFAFF",
      100: "#DBF7FF",
      200: "#A8ECFF",
      300: "#75E0FF",
      400: "#0FC9FF",
      500: "#00ABDD",
      600: "#0082A8",
      700: "#005B75",
      800: "#003B4C",
      900: "#003342",
    },
    purple: {
      50: "#FAF5FF",
      100: "#E9D8FD",
      200: "#D6BCFA",
      300: "#B794F4",
      400: "#9F7AEA",
      500: "#805AD5",
      600: "#6B46C1",
      700: "#553C9A",
      800: "#44337A",
      900: "#322659",
    },
    pink: {
      50: "#FFE3EC",
      100: "#FFB8D2",
      200: "#FF8CBA",
      300: "#F364A2",
      400: "#E8368F",
      500: "#DA127D",
      600: "#BC0A6F",
      700: "#A30664",
      800: "#870557",
      900: "#620042",
    },
  },
  styles: {
    global: {
      "html, body": {
        height: "100%",
        minHeight: "100vh",
        WebkitFontSmoothing: "antialiased",
      },
      "#root": {
        height: "100%",
      },
    },
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

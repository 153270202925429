import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  VStack,
  Text,
} from "@chakra-ui/react";

export default function AllSites_Stats() {
  return (
    <VStack spacing={7} align="stretch" width="16%">
      <StatsCard heading="04" details="Countries" />
      <StatsCard heading="83" details="Total sites" />
      <StatsCard heading="70" details="Stable status" />
      <StatsCard heading="13" details="Attention Required" />
      <StatsCard heading="03" details="Task Ongoing" />
    </VStack>
  );
}

type StatsCardProps = {
  heading: string;
  details: string;
};

const StatsCard = ({ heading, details }: StatsCardProps) => (
  <Card p={8} align="center">
    <CardHeader p={0}>
      <Heading size="lg">{heading}</Heading>
    </CardHeader>
    <CardBody p={0}>
      <Text fontSize={15}>{details}</Text>
    </CardBody>
  </Card>
);

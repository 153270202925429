/* eslint-disable */
/* THIS FILE HAS BEEN GENERATED DO NOT EDIT IT DIRECTLY */
import * as Types from '../../../graphql/__generated__/types';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePropertyMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  logoUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
  imageUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isFake: Types.Scalars['Boolean']['input'];
  country?: Types.InputMaybe<Types.Scalars['String']['input']>;
  city?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreatePropertyMutation = { __typename: 'Mutation', createProperty?: { __typename: 'Property', id: string, name: string, description?: string | null, logoUrl?: string | null, imageUrl?: string | null, isFake: boolean, createdBy: string, createdAt: string, updatedAt: string, createdByUser?: { __typename: 'User', id: string, name: string, email: string, displayPicture?: string | null, propertyId?: string | null, userType: Types.UserType, createdAt: string, updatedAt: string } | null } | null };


export const CreatePropertyDocument = gql`
    mutation createProperty($name: String!, $description: String, $logoUrl: String, $imageUrl: String, $isFake: Boolean!, $country: String, $city: String) {
  createProperty(
    name: $name
    description: $description
    logoUrl: $logoUrl
    isFake: $isFake
    imageUrl: $imageUrl
    country: $country
    city: $city
  ) {
    id
    name
    description
    logoUrl
    imageUrl
    isFake
    createdBy
    createdByUser {
      id
      name
      email
      displayPicture
      propertyId
      userType
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreatePropertyMutationFn = ApolloReactCommon.MutationFunction<CreatePropertyMutation, CreatePropertyMutationVariables>;

/**
 * __useCreatePropertyMutation__
 *
 * To run a mutation, you first call `useCreatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyMutation, { data, loading, error }] = useCreatePropertyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      logoUrl: // value for 'logoUrl'
 *      imageUrl: // value for 'imageUrl'
 *      isFake: // value for 'isFake'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useCreatePropertyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePropertyMutation, CreatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePropertyMutation, CreatePropertyMutationVariables>(CreatePropertyDocument, options);
      }
export type CreatePropertyMutationHookResult = ReturnType<typeof useCreatePropertyMutation>;
export type CreatePropertyMutationResult = ApolloReactCommon.MutationResult<CreatePropertyMutation>;
export type CreatePropertyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePropertyMutation, CreatePropertyMutationVariables>;
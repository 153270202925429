import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { LanguageCode, Room } from "../../graphql/__generated__/types";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import { CheckIcon } from "@chakra-ui/icons";
import { gql } from "@apollo/client";
import { useGuestCheckOutMutation } from "./__generated__/Checkout.graphql";
import { format } from "date-fns/format";
import ClockIcon from "../../assets/icons/ClockIcon";

interface CheckOutProps {
  room: Room;
  isOpen: boolean;
  onClose: () => void;
}
export const languageLabels: { [key in LanguageCode]: string } = {
  [LanguageCode.Eng]: "English",
  [LanguageCode.Deu]: "German",
  [LanguageCode.Fra]: "French",
  [LanguageCode.Ita]: "Italian",
  [LanguageCode.Nld]: "Dutch",
  [LanguageCode.Spa]: "Spanish",
};

export default function CheckOut({ room, isOpen, onClose }: CheckOutProps) {
  const [isCheckedout, setIsCheckedout] = useState(false);
  const [guestCheckOut, { loading }] = useGuestCheckOutMutation();
  const checkOutDate = room.checkOutDate || new Date().toISOString();

  const handleCheckOut = async () => {
    if (isCheckedout) return;
    try {
      await guestCheckOut({
        variables: {
          roomId: room.roomId,
          checkOutDate: checkOutDate,
        },
      });
      setIsCheckedout(true);
    } catch {
      setIsCheckedout(false);
    }
  };

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton />
        <DrawerHeader paddingBottom={2} fontWeight="bold">
          Check Out
          <Divider mt={3} />
        </DrawerHeader>
        <DrawerBody
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
        >
          <VStack align="start" spacing={5}>
            <Text fontWeight="600">Guest Room</Text>
            <Text p={2}>{room.roomId}</Text>
            <Text fontWeight="600">Guest Details</Text>
            <Text p={2}>{room.guestName || "Guest"} </Text>
            <Text fontWeight="600">Check out date</Text>
            <Box w="100%">
              <Box display="flex" justifyContent="space-between" w="100%" p={2}>
                <Text>{format(checkOutDate, `hh:mm aa`)}</Text>
                <ClockIcon />
              </Box>
              <Box display="flex" justifyContent="space-between" w="100%" p={2}>
                <Text>{format(checkOutDate, `do MMMM yyyy`)}</Text>
                <CalendarIcon />
              </Box>
            </Box>
            <Text fontWeight="600">Language</Text>
            <Text p={2}>
              {languageLabels[room.guestLanguage as LanguageCode] || "Empty"}
            </Text>
          </VStack>
          <Button
            backgroundColor={isCheckedout ? "#41B546" : "red"}
            w="100%"
            fontWeight="100"
            color="white"
            mb={10}
            onClick={handleCheckOut}
            isLoading={loading}
          >
            {isCheckedout && <CheckIcon color="white" w={3} h={3} mr={2} />}
            {isCheckedout ? "Checked out" : "Check Out"}
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

CheckOut.graphql = {
  mutations: {
    GuestCheckOut: gql`
      mutation GuestCheckOut($roomId: String!, $checkOutDate: String!) {
        guestCheckOut(roomId: $roomId, checkOutDate: $checkOutDate) {
          id
        }
      }
    `,
  },
};

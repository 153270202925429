import React from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  IconProps,
  ComponentWithAs,
} from "@chakra-ui/react";
import { SideNavItem } from "./SideNavItem";

export interface SideNavItemData {
  key: string;
  icon: ComponentWithAs<"svg", IconProps>;
  label: string;
  hasSubMenu?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export type SideNavProps = {
  SideNavItems: SideNavItemData[];
  activeElementKey: string;
};
export const SideNav: React.FC<SideNavProps> = ({
  SideNavItems,
  activeElementKey,
}) => {
  return (
    <Box
      as="nav"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={4}
      fontSize="xs"
      lineHeight="none"
      color={useColorModeValue("black", "white")}
      maxW="100px"
      height={"100%"}
      shadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
    >
      <Flex direction="column" flex="1" alignItems="center" w="full">
        {SideNavItems.map((item) => (
          <SideNavItem
            key={item.label}
            DisplayIcon={item.icon}
            label={item.label}
            isActive={item.key === activeElementKey}
            hasSubMenu={item.hasSubMenu}
            onClick={(e) => {
              (item.onClick ? item.onClick : () => {})(e);
            }}
          />
        ))}
      </Flex>
    </Box>
  );
};

import { createIcon } from "@chakra-ui/react";

export const BellIcon = createIcon({
  displayName: "BellIcon",
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "none",
    height: "5",
    width: "5",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 2.07653C14.9803 2.57284 17.1962 5.22699 17.6125 7.83871C17.9353 9.86408 18.4737 13.2032 18.6662 14.3959C18.8018 15.2369 18.1516 15.9953 17.3037 15.9953H4.69569C3.84837 15.9953 3.19826 15.2379 3.33303 14.3974C3.52411 13.2056 4.05989 9.86551 4.38775 7.83657C4.8096 5.22601 7.01979 2.5726 9.99995 2.07649V1.99414V0.996094V-0.00390625H11.9999V0.996094V1.99414V2.07653ZM6.36214 8.15562C6.70294 6.04664 8.67837 3.994 10.9999 3.994C13.3228 3.994 15.3021 6.04974 15.6374 8.15348C15.9091 9.85816 16.3332 12.4912 16.5757 13.9953H5.42307C5.66421 12.492 6.08668 9.86031 6.36214 8.15562ZM9.74995 16.747C9.74995 17.4373 10.3096 17.997 10.9999 17.997C11.6903 17.997 12.2499 17.4373 12.2499 16.747H14.2499C14.2499 18.5419 12.7949 19.997 10.9999 19.997C9.20502 19.997 7.74995 18.5419 7.74995 16.747H9.74995ZM16.7556 3.17287C17.9431 4.46459 18.7361 6.06071 18.9849 7.62142C19.1591 8.71438 19.3725 10.0466 19.5906 11.4047L21.5653 11.0875C21.3473 9.72977 21.134 8.39847 20.96 7.30664C20.6438 5.32287 19.6565 3.37319 18.228 1.81932L16.7556 3.17287ZM3.01847 7.62356C3.27107 6.06033 4.06485 4.46383 5.25099 3.17259L3.7781 1.81959C2.35114 3.37299 1.36451 5.32154 1.04408 7.30451C0.867417 8.39775 0.652914 9.72964 0.43457 11.0873L2.4092 11.4049C2.62751 10.0474 2.84192 8.71608 3.01847 7.62356Z"
      fill="currentColor"
    />
  ),
});

import { createIcon } from "@chakra-ui/react";

export const EyeBrokenIcon = createIcon({
  displayName: "EyeBroken",
  defaultProps: {
    width: "22",
    height: "19",
    fill: "none",
  },
  viewBox: "0 0 22 19",
  path: [
    <path
      key="1"
      d="M8 2.03035C8.97019 1.72279 9.98223 1.56761 11 1.57035C15.182 1.57035 18.028 4.07035 19.725 6.27435C20.575 7.38035 21 7.93135 21 9.57035C21 11.2103 20.575 11.7613 19.725 12.8663C18.028 15.0703 15.182 17.5703 11 17.5703C6.818 17.5703 3.972 15.0703 2.275 12.8663C1.425 11.7623 1 11.2093 1 9.57035C1 7.93035 1.425 7.37935 2.275 6.27435C2.79336 5.59705 3.37061 4.96693 4 4.39135"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />,
    <path
      key="2"
      d="M14 9.57031C14 10.366 13.6839 11.129 13.1213 11.6916C12.5587 12.2542 11.7956 12.5703 11 12.5703C10.2044 12.5703 9.44129 12.2542 8.87868 11.6916C8.31607 11.129 8 10.366 8 9.57031C8 8.77466 8.31607 8.0116 8.87868 7.44899C9.44129 6.88638 10.2044 6.57031 11 6.57031C11.7956 6.57031 12.5587 6.88638 13.1213 7.44899C13.6839 8.0116 14 8.77466 14 9.57031Z"
      stroke="black"
      strokeWidth="1.5"
    />,
  ],
});

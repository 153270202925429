import { Box } from "@chakra-ui/react";
import React from "react";

export interface DashboardTileProps {
  Main: React.ReactNode;
  Sub: string | React.ReactNode;
  alignItems?: string;
  justifyContent?: string;
}

export const DashboardTile = ({
  Main,
  Sub,
  alignItems,
  justifyContent,
}: DashboardTileProps) => (
  <Box
    width={"100%"}
    height={"100%"}
    bgColor={"#fff"}
    opacity={"86%"}
    borderRadius="12px"
    display="flex"
    alignItems={alignItems ? alignItems : "center"}
    justifyContent={justifyContent ? justifyContent : "baseline"}
  >
    <Box paddingX={7} width={"100%"}>
      {Main}
      {Sub}
    </Box>
  </Box>
);
